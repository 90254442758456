import {useState, useRef, useEffect} from 'react'
import AIDSelect from '../MainComponents/AIDSelect'

const DocHeader = ({jobsData, headerFormRef}) => {
//
//HOOKS
//

//
//EFFECTS
//

//
//FUNCS
//

//
//RETURNS
//

//This incredibly simple return returns AIDSelect (job select), as well as a date input field, price input field, and cost type input field.

return(
  <form ref={headerFormRef} className='doc-header'>
    <label htmlFor='aidSelect' className='medium-text'>AMP Id:</label>
    <AIDSelect defaultAID={'A00'} jobsData={jobsData}/>
    <div className='field-row'>
      <div className='field-column small'>
        <label htmlFor='dateField' className='medium-text'>Today's Date:</label>
        <input name="dateField" className='input-field' type="date"></input>
      </div>
      <div className='field-column small'>
        <label htmlFor='totalField' className='medium-text'>Total Price $:</label>
        <input name="totalField" className='input-field' type="number"></input>
      </div>
      <div className='field-column small'>
        <label htmlFor='typeField' className='medium-text'>Cost Type:</label>
        <select className="input-field" name='typeField'>
          <option value=''>-</option>
          <option value='labor'>Labor</option>
          <option value='material'>Material</option>
          <option value='kitchens'>Kitchens</option>
          <option value='misc'>Misc.</option>
          <option value='misc'>Estimate</option>
        </select>
      </div>
      
    </div>
  </form>
  
)}
export default DocHeader