import './TabStyles.css';
import install from './install.jpg'

const TabHome = () => {
    return (
        <div className='tab-wrapper'>
            <div className='header-image-wrapper bottom'>
                <img className='header-image' src={install}></img>
            </div>   
            <div className='tab-body-wrapper'>
                <h1 className='tab-title'>AMP CONCEPTS LLC - Founded 2022</h1>
                <h3 className='tab-header'>About</h3>
                <p className='tab-body'>AMP Concept's speciality is the supply & installation of architectural millwork products. Hence the name A-M-P (Architectural Millwork Products).
                    This company was founded and is headed by long time idustry veteran, Michael Belfonti, who has more than 30 years experience in this field of construction.
                    "Lean and Mean" is the structure of our company. We utilize every one of our team's strengths to ensure the best possible experience for our customers.
                </p>
                <h3 className='tab-header'>Virtues</h3>
                <p className='tab-body'>AMP Concepts was founded under the notion of good will. This company is more than just a simple business with intentions of turning a profit, like so many others in this industry.
                    Considering our company is turnkey (supply & install), we deal with a broad range of companies. From our customers, to the lumberyards, to our subcontractors, we treat everyone with unequivocal respect. We see our connections as more than simply opportunities to grow business, but as friends who we want to succeed just as much as we want to succeed ourselves.
                    Treating all of those who surround our company with the utmost respect is keystone of our founding, and the bridge to our future successes.
                </p>
            </div>
        </div>
    )
}
export default TabHome