import { useState, useEffect, useRef } from "react";
import {Chart} from 'react-google-charts'

const JobCard = ({jobInfo,baseUrl,setOverlay}) => {
//
//HOOKS
//

//barChartData is the top bar chart that represents contract total && debits && credits.
const [barChartData, setBarChartData] = useState()

//barChartBreakoutData is the bottom bar chart that shows projected against actually spent cost of different cost types i.e. material, labor.
const [barChartBreakoutData, setBarChartBreakoutData] = useState()

//chartOptions are all of the options for the top chart (barChartData)
const [chartOptions, setChartOptions] = useState()

//chartOptions2 are all of the options for the bottom chart (barChartBreakoutData)
const [chartOptions2, setChartOptions2] = useState()

//editState holds a boolean that represents whether or not the jobCard is in the editState or not. It defaults to false for obvious reasons.
const [editState, setEditState] = useState(false)

//jobFormRef encapsulates the form field for when a job is being edited.
const jobFormRef = useRef()

//dollarValuesState simply holds would-be integer values, but are converted to dollar sign values! Which I'll explain below.
const [dollarValuesState, setDollarValuesState] = useState({
  contractTotal: '',
  summedCosts: '',
  debitsPaid: '',
  debitsOwed: '',
  creditsBilled: '',
  creditsReceived: '',
  creditsDue: '',
})

//
//EFFECTS
//

//This behemoth of a useEffect has a lot of operations, which I'll cover in good detail.
//First we create some variables that hold number values of the relevant financial values.
//then dollarValueInvoker is created, which mirrors the already established dollarValuesState.
//the invoker turns all of the integer values that are calculated or copied above into dollar sign values. i.e. 1000 --> $1,000.00
//After that I set the different bar chart data, along with their settings!
useEffect(() => {
  let summedCostPre = ( +jobInfo[13] + +jobInfo[14] + +jobInfo[15] + +jobInfo[16] )
  let debitsPaidCopy = +jobInfo[19]
  let creditsBilledCopy = +jobInfo[17]
  let creditsReceivedCopy = +jobInfo[18]
  let contractTotalCopy = +jobInfo[8]
  let creditsDueCalc = ( +jobInfo[17] - +jobInfo[18] )
  let debitsOwedCalc = ( +summedCostPre - +jobInfo[19] )
  let moneyRemainingCopy = contractTotalCopy - summedCostPre
  console.log("m,oney remaining", contractTotalCopy - summedCostPre)
  const dollarValueInvoker = {
    summedCosts: summedCostPre.toLocaleString("en-US", {style:"currency", currency:"USD"}),
    moneyRemaining: moneyRemainingCopy.toLocaleString("en-US", {style:"currency", currency:"USD"}),
    debitsPaid: debitsPaidCopy.toLocaleString("en-US", {style:"currency", currency:"USD"}),
    creditsBilled: creditsBilledCopy.toLocaleString("en-US", {style:"currency", currency:"USD"}),
    creditsReceived: creditsReceivedCopy.toLocaleString("en-US", {style:"currency", currency:"USD"}),
    contractTotal: contractTotalCopy.toLocaleString("en-US", {style:"currency", currency:"USD"}),
    creditsDue: creditsDueCalc.toLocaleString("en-US", {style:"currency", currency:"USD"}),
    debitsOwed: debitsOwedCalc.toLocaleString("en-US", {style:"currency", currency:"USD"}),
  }
  setDollarValuesState(dollarValueInvoker)
  setBarChartData([
    ["Cost Type", "$", {role:'style'}],
    ["Contract:",+jobInfo[8], 'color: gold'],
    ["Billed:",+jobInfo[17],'color: rgb(122, 178, 25)'],
    ["Spent:", summedCostPre, 'color: red'],
  ])
  setBarChartBreakoutData([
    ["Cost Type", "Projected Cost", "Actual Spent",],
    ["Material:",+jobInfo[9], +jobInfo[13],],
    ["Labor:",+jobInfo[10], +jobInfo[14],],
    ["Kitchens:", +jobInfo[11], +jobInfo[15],],
    ["Misc.:", +jobInfo[12], +jobInfo[16],],
  ])
  setChartOptions({
    'width': '100%',
    'chartArea': {'width': '75%', 'height': '70%'},
    'legend.position': 'top',
    'labels': 'value',
    'backgroundColor' : {
        'fill':'rgb(250, 250, 250)',
        'opacity': 100
    }
  })
  setChartOptions2({
    'width': '100%',
    'chartArea': {'width': '75%', 'height': '70%'},
    'legend.position': 'top',
    colors: ["#5674cc", "red"],
    'labels': 'value',
    'backgroundColor' : {
        'fill':'rgb(250, 250, 250)',
        'opacity': 100
    }
  })
}, [])
//
//FUNCS
//


//processForm generates a FormData, appends all input fields, and sends to server.
const processForm = async() => {
  let parcelFormData = new FormData()
  parcelFormData.append('AID', jobInfo[1])
  parcelFormData.append('company', jobFormRef.current[0].value)
  parcelFormData.append('jobName', jobFormRef.current[1].value)
  parcelFormData.append('address', jobFormRef.current[2].value)
  parcelFormData.append('city', jobFormRef.current[3].value)
  parcelFormData.append('state', jobFormRef.current[4].value)
  parcelFormData.append('taxExempt', jobFormRef.current[5].value)
  parcelFormData.append('contractTotal', jobFormRef.current[6].value)
  parcelFormData.append('costMaterial', jobFormRef.current[7].value)
  parcelFormData.append('costLabor', jobFormRef.current[8].value)
  parcelFormData.append('costKitchen', jobFormRef.current[9].value)
  parcelFormData.append('costMisc', jobFormRef.current[10].value)
  const res = await fetch(baseUrl + 'editing/job', {
    method: 'POST',
    body: parcelFormData
  }).then(res => (res.json())).then(data => console.log(data))

  //REFRESH JOB DATA

}

//
//RETURNS
//
if (jobInfo[1] == 'ALL') {
  return(
    <div className="card-wrapper job">
      <div className="card-info-wrapper medium-text job">
        <div className="card-info-column ">
          <div className="card-info">AID: <div style={{fontWeight:"bold"}}>{jobInfo[1]}</div></div>
          <div className="card-info">Company: <div style={{fontWeight:"bold"}}>{jobInfo[2]}</div></div>
          <div className="card-info">Name: <div style={{fontWeight:"bold"}}>{jobInfo[3]}</div></div>
        </div>
        <div className="card-info-column">
          <div className="card-info">Address: <div style={{fontWeight:"bold"}}>{jobInfo[4]}</div></div>
          <div className="card-info">City: <div style={{fontWeight:"bold"}}>{jobInfo[5]}</div></div>
          <div className="card-info">Tax Exempt: <div style={{fontWeight:"bold"}}>{jobInfo[7]}</div></div>
          
        </div>
      </div>

      <div className="card-info-wrapper medium-text job">
        <div className="card-info-column">
        <div className="card-info-column">
          <div className="card-info">Contract: </div>
          <div className="card-info">Spent: </div>
          <div className="card-info">Remaining: </div>
          <div>-</div>
          <div className="card-info">Billed out: </div>
          <div className="card-info">Collected: </div>
          <div className="card-info">AMP Owed: </div>
        </div>
        </div>
        <div className="card-info-column">
          <div className="card-info">{dollarValuesState.contractTotal}</div>
          <div className="card-info">{dollarValuesState.summedCosts}</div>
          <div className="card-info line">{dollarValuesState.moneyRemaining}</div>
          <div>-</div>
          <div className="card-info">{dollarValuesState.creditsBilled}</div>
          <div className="card-info">{dollarValuesState.creditsReceived}</div>
          <div className="card-info line">{dollarValuesState.creditsDue}</div>
        </div>
      </div>
      <div className="card-info-wrapper graph">
        <Chart
          chartType="BarChart"
          options={chartOptions}
          data={barChartData}
        />
      </div>
      <div className="card-info-wrapper graph">
        <Chart
          chartType="Bar"
          options={chartOptions2}
          data={barChartBreakoutData}
        />
      </div>
    </div>
  )
} else if (editState == false) {
  return(
    <div className="card-wrapper job">
      <div className="card-info-wrapper medium-text job">
      <div className="card-info-column ">
          <div className="card-info">AID: <div style={{fontWeight:"bold"}}> {jobInfo[1]}</div></div>
          <div className="card-info">Company: <div style={{fontWeight:"bold"}}> {jobInfo[2]}</div></div>
          <div className="card-info">Name: <div style={{fontWeight:"bold"}}> {jobInfo[3]}</div></div>
        </div>
        <div className="card-info-column">
          <div className="card-info">Address: <div style={{fontWeight:"bold"}}> {jobInfo[4]}</div></div>
          <div className="card-info">City: <div style={{fontWeight:"bold"}}> {jobInfo[5]}, {jobInfo[6]}</div></div>
          <div className="card-info">Tax Exempt: <div style={{fontWeight:"bold"}}> {jobInfo[7]}</div></div>
          
        </div>
      </div>

      <div className="card-info-wrapper medium-text job">
        <div className="card-info-column">
          <div className="card-info">Contract: </div>
          <div className="card-info">Spent: </div>
          <div className="card-info">Remaining: </div>
          <div>-</div>
          <div className="card-info">Billed out: </div>
          <div className="card-info">Collected: </div>
          <div className="card-info">AMP Owed: </div>
        </div>
        <div className="card-info-column">
          <div className="card-info">{dollarValuesState.contractTotal}</div>
          <div className="card-info">{dollarValuesState.summedCosts}</div>
          <div className="card-info line">{dollarValuesState.moneyRemaining}</div>
          <div>-</div>
          <div className="card-info">{dollarValuesState.creditsBilled}</div>
          <div className="card-info">{dollarValuesState.creditsReceived}</div>
          <div className="card-info line">{dollarValuesState.creditsDue}</div>
        </div>
      </div>
      <div className="card-info-wrapper graph">
        <Chart
          chartType="BarChart"
          options={chartOptions}
          data={barChartData}
        />
      </div>
      <div className="card-info-wrapper graph small" >
        <Chart
          chartType="Bar"
          options={chartOptions2}
          data={barChartBreakoutData}
        />
      </div>
      <div style={{height: '10%'}} >.</div>
      <div onDoubleClick={() => setEditState(true)} className="input-field button jobs">DOUBLE CLICK TO EDIT</div>
    </div>
  )
} else if (editState == true) {
  return(<form className="card-wrapper job" ref={jobFormRef}  >
    <div>{jobInfo[1]}</div>
<div className="card-info-wrapper edit">
  
  <div className="card-info-row">
    <div className='medium-text'>Company : </div>
    <input className="input-field job" type="text" defaultValue={jobInfo[2]}></input>
  </div>
  <div className="card-info-row">
    <div className='medium-text'>Job Name : </div>
    <input className="input-field job" type="text" defaultValue={jobInfo[3]}></input>
  </div>
  <div className="card-info-row">
    <div className='medium-text'>Address : </div>
    <input className="input-field job" type="text" defaultValue={jobInfo[4]}></input>
  </div>
  <div className="card-info-row">
    <div className='medium-text'>City : </div>
    <input className="input-field job" type="text" defaultValue={jobInfo[5]}></input>
  </div>
  <div className="card-info-row">
    <div className='medium-text'>State : </div>
    <input className="input-field job" type="text" defaultValue={jobInfo[6]}></input>
  </div>
  <div className="card-info-row">
    <div className='medium-text'>Tax Exempt? : </div>
    <input className="input-field job" type="text" defaultValue={jobInfo[7]}></input>
  </div>
  <div className="card-info-row">
    <div className='medium-text'>Contract Total $: </div>
    <input className="input-field job" type="number" defaultValue={jobInfo[8]}></input>
  </div>
  <div className="card-info-row">
    <div className='medium-text'>Material Cost $: </div>
    <input className="input-field job" type="number" defaultValue={jobInfo[9]}></input>
  </div>
  <div className="card-info-row">
    <div className='medium-text'>Labor Cost $: </div>
    <input className="input-field job" type="number" defaultValue={jobInfo[10]}></input>
  </div>
  <div className="card-info-row">
    <div className='medium-text'>Kitchen Cost $: </div>
    <input className="input-field job" type="number" defaultValue={jobInfo[11]}></input>
  </div>
  <div className="card-info-row">
    <div className='medium-text'>Misc Cost $: </div>
    <input className="input-field job" type="number" defaultValue={jobInfo[12]}></input>
  </div>
 

</div>
<div onDoubleClick={() => {setEditState(false);processForm()}} className="input-field button jobs green">DOUBLE CLICK TO SUBMIT</div>
    </form>)
  
} else {
  return(<div>one moment..</div>)
}
  
  

}
export default JobCard