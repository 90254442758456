import {useState, useRef, useEffect} from 'react'

const HardwareSmallBlockComponent = ({itemData}) => {

    const [hardwareData, setHardwareData] = useState()

    useEffect(() => {
        try {
            setHardwareData(itemData)
        } catch(err) {
            console.error(err)
        }
        
    }, [])


    if (hardwareData) {
        return (
        
            <div className="hardware-block-wrapper small">
                <div className="img-frame hardware">
                <img className='item-img hardware' src={hardwareData[7]}></img>
                </div>
                <div className="hardware-info-wrapper">
                    <div className="info-cell hardware">•MNFR : {hardwareData[1]}</div>
                    <div className="info-cell hardware">•{hardwareData[2]}</div>
                    <div className="info-cell hardware">•MODEL# : {hardwareData[3]} </div>
                    <div className="info-cell hardware">•FINISH : {hardwareData[4]} </div>
                    <div className="info-cell hardware">•QTY : {hardwareData[5]} </div>
                </div>
            </div>
        )        
    } else {
        return (<div>Please wait..</div>)
    }
}
export default HardwareSmallBlockComponent