import { useState, useEffect, useCallback, useRef } from "react"
import PrintPage from "./PrintPage"
import logoTitle from './watermark-title.png'
import logo from './watermark.png'
import headerLogo from './AAlogo-shadow.png'
import PrintCondensed from "./PrintCondensed"
// import ReactToPrint from 'react-to-print'
import {useReactToPrint} from 'react-to-print'

const OrderComponent = ({chosenURL}) => {
  console.log("chosenURL", chosenURL)
    const [baseUrl, setBaseUrl] = useState('https://us-central1-amp-website-88a6f.cloudfunctions.net/submittalDataFetch')
    const [submittalExtension, setSubmittalExtension] = useState('submittals/CourthouseSQ')
    // const [baseUrl, setBaseUrl] = useState('http://localhost:1396/')
    const [allDoorSheetData, setAllDoorSheetData] = useState()
    const [doorCodes, setDoorCodes] = useState()
    const [hardwareCodes, setHardwareCodes] = useState()
    const [hardwareRepo, setHardwareRepo] = useState()
    const [doorImages, setDoorImages] = useState()
    const [jobInfo, setJobInfo] = useState()
    const [itemIDCountObject, setItemIDCountObject] = useState(null)
    const [uniqueDoorsList, setUniqueDoorsList] = useState(null)
    const [totalDoors, setTotalDoors] = useState(0)
    const [hardwareToggle, setHardwareToggle] = useState(true)
    const [doorCountsToggle, setDoorCountsToggle] = useState(false)
    const [hardwareCountsToggle, setHardwareCountsToggle] = useState(false)
    const [doorScheduleToggle, setDoorScheduleToggle] = useState(true)
    const [hardwareScheduleToggle,setHardwareScheduleToggle] = useState(true)
    const [boylePagesToggle,setBoylePagesToggle] = useState(false)

    const [chosenOrientation,setChosenOrientation] = useState("landscape")


    const contentRef = useRef();
    const handlePrint = useReactToPrint({contentRef});

    const doorCodesRef = useRef()
    const hardwareCodesRef = useRef()
    const hardwareRepoRef = useRef()
    const jobInfoRef = useRef()
    const arrayOfUniqueDoorsRef = useRef([])

    const arrayOfHardwareCodes = useRef([])
    const objectOfItemIDCounts = useRef([{
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      "8": 0,
      "9": 0,
      "10": 0,
      "11": 0,
      "12": 0,
      "13": 0,
      "14": 0,
      "15": 0,
      "16": 0,
      "17": 0,
      "18": 0,
      "19": 0,
      "20": 0,
      "21": 0,
      "22": 0,
      "23": 0,
      "24": 0,
      "25": 0,
      "26": 0,
      "27": 0,
      "28": 0,
      "29": 0,
      "30": 0,
      "31": 0,
      "32": 0,
      "33": 0,
      "34": 0,
      "35": 0,
      "36": 0,
      "37": 0,
      "38": 0,
      "39": 0,
      "40": 0,
      "41": 0,
      "42": 0,
      "43": 0,
      "44": 0,
      "45": 0,
      "46": 0,
      "47": 0,
      "48": 0,
      "49": 0,
      "50": 0,
      "51": 0,
      "52": 0,
      "53": 0,
      "54": 0,
      "55": 0,
      "56": 0,
      "57": 0,
      "58": 0,
      "59": 0,
      "60": 0,
      "61": 0,
      "62": 0,
      "63": 0,
      "64": 0,
      "65": 0,
      "66": 0,
      "67": 0,
      "68": 0,
      "69": 0,
      "70": 0,
      "71": 0,
      "72": 0,
      "73": 0,
      "74": 0,
      "75": 0,
      "76": 0,
      "77": 0,
      "78": 0,
      "79": 0,
      "80": 0,
      "81": 0,
      "82": 0,
      "83": 0,
      "84": 0,
      "85": 0,
      "86": 0,
      "87": 0,
      "88": 0,
      "89": 0,
      "90": 0,
      "91": 0,
      "92": 0,
      "93": 0,
      "94": 0,
      "95": 0,
      "96": 0,
      "97": 0,
      "98": 0,
      "99": 0,
      "100": 0,
      "101": 0,
      "102": 0,
      "103": 0,
      "104": 0,
      "105": 0,
      "106": 0,
      "107": 0,
      "108": 0,
      "109": 0,
      "110": 0,
      "111": 0,
      "112": 0,
      "113": 0,
      "114": 0,
      "115": 0,
      "116": 0,
      "117": 0,
      "118": 0,
      "119": 0,
      "120": 0,
      "121": 0,
      "122": 0,
      "123": 0,
      "124": 0,
      "125": 0,
      "126": 0,
      "127": 0,
      "128": 0,
      "129": 0,
      "130": 0,
      "131": 0,
      "132": 0,
      "133": 0,
      "134": 0,
      "135": 0,
      "136": 0,
      "137": 0,
      "138": 0,
      "139": 0,
      "140": 0,
      "141": 0,
      "142": 0,
      "143": 0,
      "144": 0,
      "145": 0,
      "146": 0,
      "147": 0,
      "148": 0,
      "149": 0,
      "150": 0,
      "151": 0,
      "152": 0,
      "153": 0,
      "154": 0,
      "155": 0,
      "156": 0,
      "157": 0,
      "158": 0,
      "159": 0,
      "160": 0,
      "161": 0,
      "162": 0,
      "163": 0,
      "164": 0,
      "165": 0,
      "166": 0,
      "167": 0,
      "168": 0,
      "169": 0,
      "170": 0,
      "171": 0,
      "172": 0,
      "173": 0,
      "174": 0,
      "175": 0,
      "176": 0,
      "177": 0,
      "178": 0,
      "179": 0,
      "180": 0,
      "181": 0,
      "182": 0,
      "183": 0,
      "184": 0,
      "185": 0,
      "186": 0,
      "187": 0,
      "188": 0,
      "189": 0,
      "190": 0,
      "191": 0,
      "192": 0,
      "193": 0,
      "194": 0,
      "195": 0,
      "196": 0,
      "197": 0,
      "198": 0,
      "199": 0,
      "200": 0,
      "201": 0,
      "202": 0,
      "203": 0,
      "204": 0,
      "205": 0,
      "206": 0,
      "207": 0,
      "208": 0,
      "209": 0,
      "210": 0,
      "211": 0,
      "212": 0,
      "213": 0,
      "214": 0,
      "215": 0,
      "216": 0,
      "217": 0,
      "218": 0,
      "219": 0,
      "220": 0,
      "221": 0,
      "222": 0,
      "223": 0,
      "224": 0,
      "225": 0,
      "226": 0,
      "227": 0,
      "228": 0,
      "229": 0,
      "230": 0,
      "231": 0,
      "232": 0,
      "233": 0,
      "234": 0,
      "235": 0,
      "236": 0,
      "237": 0,
      "238": 0,
      "239": 0,
      "240": 0,
      "241": 0,
      "242": 0,
      "243": 0,
      "244": 0,
      "245": 0,
      "246": 0,
      "247": 0,
      "248": 0,
      "249": 0,
      "250": 0,
      "251": 0,
      "252": 0,
      "253": 0,
      "254": 0,
      "255": 0,
      "256": 0,
      "257": 0,
      "258": 0,
      "259": 0,
      "260": 0,
      "261": 0,
      "262": 0,
      "263": 0,
      "264": 0,
      "265": 0,
      "266": 0,
      "267": 0,
      "268": 0,
      "269": 0,
      "270": 0,
      "271": 0,
      "272": 0,
      "273": 0,
      "274": 0,
      "275": 0,
      "276": 0,
      "277": 0,
      "278": 0,
      "279": 0,
      "280": 0,
      "281": 0,
      "282": 0,
      "283": 0,
      "284": 0,
      "285": 0,
      "286": 0,
      "287": 0,
      "288": 0,
      "289": 0,
      "290": 0,
      "291": 0,
      "292": 0,
      "293": 0,
      "294": 0,
      "295": 0,
      "296": 0,
      "297": 0,
      "298": 0,
      "299": 0,
      "300": 0,
      "301": 0,
      "302": 0,
      "303": 0,
      "304": 0,
      "305": 0,
      "306": 0,
      "307": 0,
      "308": 0,
      "309": 0,
      "310": 0,
      "311": 0,
      "312": 0,
      "313": 0,
      "314": 0,
      "315": 0,
      "316": 0,
      "317": 0,
      "318": 0,
      "319": 0,
      "320": 0,
      "321": 0,
      "322": 0,
      "323": 0,
      "324": 0,
      "325": 0,
      "326": 0,
      "327": 0,
      "328": 0,
      "329": 0,
      "330": 0,
      "331": 0,
      "332": 0,
      "333": 0,
      "334": 0,
      "335": 0,
      "336": 0,
      "337": 0,
      "338": 0,
      "339": 0,
      "340": 0,
      "341": 0,
      "342": 0,
      "343": 0,
      "344": 0,
      "345": 0,
      "346": 0,
      "347": 0,
      "348": 0,
      "349": 0,
      "350": 0,
      "351": 0,
      "352": 0,
      "353": 0,
      "354": 0,
      "355": 0,
      "356": 0,
      "357": 0,
      "358": 0,
      "359": 0,
      "360": 0,
      "361": 0,
      "362": 0,
      "363": 0,
      "364": 0,
      "365": 0,
      "366": 0,
      "367": 0,
      "368": 0,
      "369": 0,
      "370": 0,
      "371": 0,
      "372": 0,
      "373": 0,
      "374": 0,
      "375": 0,
      "376": 0,
      "377": 0,
      "378": 0,
      "379": 0,
      "380": 0,
      "381": 0,
      "382": 0,
      "383": 0,
      "384": 0,
      "385": 0,
      "386": 0,
      "387": 0,
      "388": 0,
      "389": 0,
      "390": 0,
      "391": 0,
      "392": 0,
      "393": 0,
      "394": 0,
      "395": 0,
      "396": 0,
      "397": 0,
      "398": 0,
      "399": 0,
      "400": 0,
      "401": 0,
      "402": 0,
      "403": 0,
      "404": 0,
      "405": 0,
      "406": 0,
      "407": 0,
      "408": 0,
      "409": 0,
      "410": 0,
      "411": 0,
      "412": 0,
      "413": 0,
      "414": 0,
      "415": 0,
      "416": 0,
      "417": 0,
      "418": 0,
      "419": 0,
      "420": 0,
      "421": 0,
      "422": 0,
      "423": 0,
      "424": 0,
      "425": 0,
      "426": 0,
      "427": 0,
      "428": 0,
      "429": 0,
      "430": 0,
      "431": 0,
      "432": 0,
      "433": 0,
      "434": 0,
      "435": 0,
      "436": 0,
      "437": 0,
      "438": 0,
      "439": 0,
      "440": 0,
      "441": 0,
      "442": 0,
      "443": 0,
      "444": 0,
      "445": 0,
      "446": 0,
      "447": 0,
      "448": 0,
      "449": 0,
      "450": 0,
      "451": 0,
      "452": 0,
      "453": 0,
      "454": 0,
      "455": 0,
      "456": 0,
      "457": 0,
      "458": 0,
      "459": 0,
      "460": 0,
      "461": 0,
      "462": 0,
      "463": 0,
      "464": 0,
      "465": 0,
      "466": 0,
      "467": 0,
      "468": 0,
      "469": 0,
      "470": 0,
      "471": 0,
      "472": 0,
      "473": 0,
      "474": 0,
      "475": 0,
      "476": 0,
      "477": 0,
      "478": 0,
      "479": 0,
      "480": 0,
      "481": 0,
      "482": 0,
      "483": 0,
      "484": 0,
      "485": 0,
      "486": 0,
      "487": 0,
      "488": 0,
      "489": 0,
      "490": 0,
      "491": 0,
      "492": 0,
      "493": 0,
      "494": 0,
      "495": 0,
      "496": 0,
      "497": 0,
      "498": 0,
      "499": 0
    }])
    
//""react-to-print" did not receive a `contentRef` option or a optional-content param pass to its callback."

  const postData = async () => {
    try {
      const response = await fetch(baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ sheetID: chosenURL })
      });

      const responseData = await response.json();
      if (!response.ok) {
        console.error(responseData)
      }
      return responseData;
    } catch (error) {
      // console.error('There was a problem with the fetch operation:', error);
    }
  };

  const getPageMargins = () => {
    if (chosenOrientation == "landscape") {
      return `@page {size: landscape}`
    } else if (chosenOrientation == "portrait") {
      return `@page {size: portrait}`
    }
    return ;
  };



  useEffect(() => {
    const runFetch = async () => {
      try {
        const data = await postData();
        if (data) {
          console.log(data.doorOrderData);
          setAllDoorSheetData(data);
  
          // Assuming that jobInfo is now directly the first array under doorOrderData[0]
          jobInfoRef.current = data.doorOrderData[0]; // Access directly
          setJobInfo(jobInfoRef.current);
  
          // Similarly updating other properties based on the updated structure
          doorCodesRef.current = data.doorOrderData[1]; // Access directly
          setDoorCodes(doorCodesRef.current);
  
          hardwareCodesRef.current = data.doorOrderData[2]; // Access directly
          setHardwareCodes(hardwareCodesRef.current);
  
          hardwareRepoRef.current = data.doorOrderData[3]; // Access directly
          setHardwareRepo(hardwareRepoRef.current);
  
          setDoorImages(data.doorOrderData[4]);
  
          // Continue with further processing
          doorCodesRef.current.forEach((doorCode) => {
            for (let i = 0; i < +doorCode[3]; i++) {
              arrayOfHardwareCodes.current.push(doorCode[13]);
            }
  
            let doorObjData = {
              qty: +doorCode[3],
              width: doorCode[6],
              height: doorCode[7],
              thickness: doorCode[8],
              material: doorCode[9],
              fire: doorCode[10],
              profile: doorCode[11],
              bore: doorCode[12],
            };
  
            arrayOfUniqueDoorsRef.current.push(doorObjData);
          });
  
          arrayOfHardwareCodes.current.forEach((codeMatch) => {
            hardwareCodesRef.current.forEach((callingCode) => {
              if (codeMatch === callingCode[0]) {
                for (let i = 1; i < callingCode.length; i++) {
                  objectOfItemIDCounts.current[0][callingCode[i]]++;
                }
              }
            });
          });
  
          setItemIDCountObject({ ...objectOfItemIDCounts.current[0] });
  
          // Filter and calculate unique doors as before
          let uniqueDoorsArray = [];
          let totalDoorAccumulator = 0;
  
          arrayOfUniqueDoorsRef.current.forEach((value, index, self) => {
            const existingIndex = uniqueDoorsArray.findIndex((t) => (
              t.width === value.width &&
              t.height === value.height &&
              t.thickness === value.thickness &&
              t.material === value.material &&
              t.fire === value.fire &&
              t.profile === value.profile &&
              t.bore === value.bore
            ));
  
            if (existingIndex === -1) {
              uniqueDoorsArray.push({ ...value });
            } else {
              uniqueDoorsArray[existingIndex].qty += +value.qty;
            }
  
            totalDoorAccumulator += +value.qty;
          });
  
          setUniqueDoorsList(uniqueDoorsArray);
          setTotalDoors(totalDoorAccumulator);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    runFetch();
  }, []);
  



const chunkArray = (array, maxNum) => {
  const result = [];
  for (let i = 0; i < array.length; i += maxNum) {
    result.push(array.slice(i, i + maxNum));
  }
  console.log(result)
  return result;
};

const HeaderPage = () => {
      if (uniqueDoorsList != null) {
        return (
            <div className="page-wrapper">
              <div style={{fontFamily: "Calibri,sans-serif"}} className="body-wrapper">
                {/* <div className="watermark-wrapper header">
                    <img className="logo-img" src={logo}></img>
                </div> */}
                  <div className="header-img-frame">
                    <img className="header-img"
                    style={{width: "200px", marginTop: "2%"}}
                    src={headerLogo}></img>
                  </div>
                  <h2 style={{fontFamily: "Georgia"}}>Door and Hardware Submittal:</h2>
                  <h3 style={{fontFamily: "Georgia"}}>{jobInfo[1][0]} - {jobInfo[1][1]} - {jobInfo[1][2]}</h3>
                  <div className="header-info-wrapper">
                    <div className="header-info">
                      <div className="header-text">Submit To: {jobInfo[1][4]}</div>
                      <div className="header-text">Email: {jobInfo[1][5]}</div>
                      <div className="header-text">Phone #: {jobInfo[1][6]}</div>
                    </div>
                    <div className="header-info">
                    <div className="header-text">Sent By: AMP Concepts LLC</div>
                      <div className="header-text">Email: <a href="mailto:sebastian@ampconceptsllc.com">sebastian@ampconceptsllc.com</a></div>
                      <div className="header-text">Phone #: 484-375-5807</div>
                    </div>

                    <div className="header-info">
                    <div className="header-text">Submitted On -- {jobInfo[1][7]}</div>
                      <div className="header-text">Tax Exemption -- {jobInfo[1][8]}</div>
                      <div className="header-text">Plan Reference Date -- {jobInfo[1][9]}</div>
                    </div>
                    <div className="header-info">
                    <div className="header-text">Note #1 -- {jobInfo[1][10]}</div>
                      <div className="header-text">Note #2 -- {jobInfo[1][11]}</div>
                      <div className="header-text">Note #3 -- {jobInfo[1][12]}</div>
                    </div>
                  </div>
                    
              </div>
            </div>
        )
        } else {
            return <div>...</div>
        }
}

const BoylePages = () => {
  return(
      <>
        {doorCodes.map((doorCode, index) =>
          <div id={`printPage-${index}`} key={Math.random()}>
            <PrintPage
              index={+index + 1}
              doorCode={doorCode}
              doorImage={doorImages.filter(img => doorCode[16] == img[0])}
              hardwareCodes={hardwareCodes.filter(code => doorCode[13] == code[0])} 
              hardwareRepo={hardwareRepo} 
              hardwareToggle={hardwareToggle}
              key={Math.random()}
            />
          </div>
        )}
      </>
  )
}

const DoorSchedulePage = () => {
  const chunkedSchedules = chunkArray(doorCodes, 20)
  return (
    <>
      {chunkedSchedules.map((chunk, chunkIndex) => (
        <div key={`chunk-${chunkIndex}`} style={{ display: "flex", justifyContent: "center", margin: 10, pageBreakAfter:"always" }}>
          <table className="totals-table condensed">
            <thead>
              <tr className="totals-row header main">
                <th className="totals-data header" colSpan={15}>Door Schedule - Page {chunkIndex + 1}</th>
              </tr>
              <tr className="totals-row header main" style={{ fontSize: 14 }}>
                <th className="totals-data header">#</th>
                <th className="totals-data header">Loc</th>
                <th className="totals-data header">Mat</th>
                <th className="totals-data header">Qty</th>
                <th className="totals-data header">Profile</th>
                <th className="totals-data header">Bore</th>
                <th className="totals-data header">Twin</th>
                <th className="totals-data header">Hand</th>
                <th className="totals-data header">Size</th>
                <th className="totals-data header">Thk</th>
                <th className="totals-data header">Fire</th>
                <th className="totals-data header">R.O.</th>
                <th className="totals-data header">Throat</th>
                <th className="totals-data header">Frame</th>
                <th className="totals-data header">HW #</th>
              </tr>
            </thead>
            <tbody>
              {chunk.map((doorCode, index) => (
                <PrintCondensed
                  key={`door-${doorCode[0]}-${index}`}
                  index={index + 1}
                  doorCode={doorCode}
                  doorImage={doorImages.filter(img => doorCode[16] === img[0])}
                  hardwareCodes={hardwareCodes.filter(code => doorCode[13] === code[0])}
                  hardwareRepo={hardwareRepo}
                  hardwareToggle={hardwareToggle}
                />
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </>
  );
};


const HardwareCountsPage = () => {
  
    if (itemIDCountObject != null) {
      const chunkedSchedules = chunkArray(doorCodes, 35)

        return (
              <div style={{display: "flex", justifyContent:"center", margin: 10, height: "95%"}}>
                <table className="totals-table counts">
                    <thead>
                        <tr className="totals-row header main">
                            <th className="totals-data header" colSpan="6" >Hardware Quantities</th>
                        </tr>
                        <tr className="totals-row header">
                            <th className="totals-data header">ID #</th>
                            <th className="totals-data header">MNF</th>
                            <th className="totals-data header">ITEM</th>
                            <th className="totals-data header">MODEL #</th>
                            <th className="totals-data header">FINISH</th>
                            <th className="totals-data header">QTY</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(itemIDCountObject).map(ID => <HardwareTableRow ID={ID} key={Math.random()}/>)}
                    </tbody>
                    
                </table>
              </div>
              
        )


    } else {
        return (
            <div>please wait..</div>
        )
    }
}
const DoorCountsPage = () => {
  if (uniqueDoorsList != null) {
      return (
        <div style={{display: "flex", justifyContent:"center", margin: 10}}>
          <table className="totals-table counts">
              <thead>
                  <tr className="totals-row header main">
                      <th className="totals-data header" colSpan="9" >Door Quantities</th>
                  </tr>
                  <tr className="totals-row header">
                      <th className="totals-data header">#</th>
                      <th className="totals-data header">MATERIAL</th>
                      <th className="totals-data header">PROFILE</th>
                      <th className="totals-data header">WIDTH</th>
                      <th className="totals-data header">HEIGHT</th>
                      <th className="totals-data header">THICK</th>
                      <th className="totals-data header">FIRE</th>
                      <th className="totals-data header">BORE</th>
                      <th className="totals-data header">QTY</th>
                  </tr>
              </thead>
              <tbody>
                  {uniqueDoorsList && uniqueDoorsList.map((doorData, index) => <DoorTableRow index={index} doorData={doorData} key={Math.random()}/> )}
              </tbody>
              <tr className="totals-row header">
                  <th className="totals-data header" colSpan={9}>TOTAL DOORS : {(totalDoors).toLocaleString('en')}</th>

              </tr>
          </table>
        </div>
      )
      } else {
          return <div>...</div>
      }
}

const HardwareSchedulePage = () => {
  if (itemIDCountObject != null) {
      return (

        <div style={{margin: 10}}>
              {hardwareCodes.map((data, i) => <HardwareScheduleRow data={data} i={i} key={Math.random()}/>)}
        </div>

      )
  } else {
      return (
          <div>please wait..</div>
      )
  }
}


const aggregateHardwareCodes = (hardwareCodes) => {
  const aggregateMap = {};

  hardwareCodes.map((hwCode, i ) => {
    // console.log(hwCode)
    const matchingHWData = hardwareRepo.filter(hardwareItem => hardwareItem[0] === hwCode);
    // console.log(matchingHWData)
    if (matchingHWData.length > 0) {
      if (matchingHWData[0][8] == "TRUE") {
        const key = hwCode;
        if (!aggregateMap[key]) {
          aggregateMap[key] = { ...matchingHWData[0], quantity: 0 };
        }
        aggregateMap[key].quantity += parseInt(matchingHWData[0][5], 10);
      }

    }
  });
  // console.log(aggregateMap)
  return Object.values(aggregateMap);
};

const HardwareScheduleRow = ({data, i}) => {
  if (data[1] == 0) {return}
  if (!hardwareCodes[i]) {return}
  const aggregatedData = aggregateHardwareCodes(hardwareCodes[i]);
  // const uniqueHardwareInfo = hardwareRepoRef.current.filter(hardwareItem => hardwareItem[0] == data[1])
  return (
    <table className="totals-table schedule">
      <thead>
        <tr className="totals-row header">
          <th className="totals-data header" colSpan={5} style={{ textAlign: "center", fontSize: "1.2rem" }}>
            {hardwareCodes[i][0]} {/* Adjust this if the header is dynamic */}
          </th>
        </tr>
        <tr className="totals-row header schedule">
          <td className="totals-data" style={{ width: "15%" }}>Manufacturer</td>
          <td className="totals-data" style={{ width: "15%" }}>Model #</td>
          <td className="totals-data" style={{ width: "40%" }}>Description</td>
          <td className="totals-data" style={{ width: "25%" }}>Finish</td>
          <td className="totals-data" style={{ width: "5%" }}>QTY</td>
        </tr>
      </thead>
      <tbody>
          {aggregatedData.map((matchingHWData, j) => (
            <tr className="totals-row" key={j}>
              <td className="totals-data">{matchingHWData[1]}</td>
              <td className="totals-data">{matchingHWData[3]}</td>
              <td className="totals-data">{matchingHWData[2]}</td>
              <td className="totals-data">{matchingHWData[4]}</td>
              <td className="totals-data">{matchingHWData.quantity}</td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}



const HardwareTableRow = ({ID}) => {
  if (ID[1] == 0) {return}
  const uniqueHardwareInfo = hardwareRepoRef.current.filter(hardwareItem => hardwareItem[0] == ID[0])
  // console.log(uniqueHardwareInfo)
  return (
      <tr className="totals-row">
          <td className="totals-data">{uniqueHardwareInfo[0][0]}</td>
          <td className="totals-data">{uniqueHardwareInfo[0][1]}</td>
          <td className="totals-data">{uniqueHardwareInfo[0][2]}</td>
          <td className="totals-data">{uniqueHardwareInfo[0][3]}</td>
          <td className="totals-data">{uniqueHardwareInfo[0][4]}</td>
          <td className="totals-data">{(+ID[1] * +uniqueHardwareInfo[0][5]).toLocaleString('en')}</td>
      </tr>
  )
}
const DoorTableRow = ({doorData, index}) => {
      return(
      <tr className="totals-row counts">
          <td className="totals-data">{index}</td>
          <td className="totals-data">{doorData.material}</td>
          <td className="totals-data">{doorData.profile}</td>
          <td className="totals-data">{doorData.width}</td>
          <td className="totals-data">{doorData.height}</td>
          <td className="totals-data">{doorData.thickness}</td>
          <td className="totals-data">{doorData.fire}</td>
          <td className="totals-data">{doorData.bore}</td>
          <td className="totals-data">{(doorData.qty).toLocaleString('en')}</td>
      </tr>
      )
}






    return(
        <div className="order-body">
            <div className="header-top-wrapper">
            {/* <ReactToPrint 
                trigger={() => <button>PRINT AS PDF</button>}
                content={() => printZone.current}
            /> */}
            <div style={{display:"flex",flexDirection:"row", justifyContent:"space-evenly", width:"100%"}}>

            <button onClick={handlePrint}>PRINT</button>

            <div className="clickable-box-header" style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="range"
                  min="0"
                  max="1"
                  value={chosenOrientation === "portrait" ? 0 : 1}
                  onChange={() => setChosenOrientation((prev) => (prev === "portrait" ? "landscape" : "portrait"))}
                  style={{ marginRight: '10px', width: '60px', cursor: 'pointer' }}
                />
                <div style={{ color: 'white', fontSize: "1rem", userSelect: 'none' }}>
                  Orientation: {chosenOrientation.charAt(0).toUpperCase() + chosenOrientation.slice(1)}
                </div>
              </div>
              <div style={{width:"10px"}} />
              <div className="clickable-box-header" onClick={()=>setHardwareCountsToggle(prev => !prev)}>
                <input name="hardware_check" type="checkbox" checked={hardwareCountsToggle} className="hardware-toggle-switch" ></input>
                <div style={{color: 'white', fontSize: "1rem"}} htmlFor="hardware_check">Hardware Counts</div>
              </div>
              <div style={{width:"10px"}} />
              <div className="clickable-box-header" onClick={()=>setDoorCountsToggle(prev => !prev)}>
                <input name="hardware_check" type="checkbox" checked={doorCountsToggle} className="hardware-toggle-switch" ></input>
                <div style={{color: 'white', fontSize: "1rem"}} htmlFor="hardware_check">Door Counts</div>
              </div>
              <div className="clickable-box-header"  onClick={()=>setDoorScheduleToggle(prev => !prev)}>
                <input name="condensedCheck" type="checkbox" checked={doorScheduleToggle} className="hardware-toggle-switch" ></input>
                <div style={{color: 'white', fontSize: "1rem"}} htmlFor="condensedCheck">Door Schedule</div>
              </div>
              <div style={{width:"10px"}} />
              <div className="clickable-box-header" onClick={()=>setHardwareScheduleToggle(prev => !prev)}>
                <input name="hardware_check" type="checkbox" checked={hardwareScheduleToggle} className="hardware-toggle-switch" ></input>
                <div style={{color: 'white', fontSize: "1rem"}} htmlFor="hardware_check">Hardware Schedule</div>
              </div>  
              <div style={{width:"10px"}} />
              <div className="clickable-box-header" onClick={()=>setBoylePagesToggle(prev => !prev)}>
                <input name="hardware_check" type="checkbox" checked={boylePagesToggle} className="hardware-toggle-switch" ></input>
                <div style={{color: 'white', fontSize: "1rem"}} htmlFor="hardware_check">Picture Pages</div>
              </div>
              

            </div>
            </div>
            
            <div ref={contentRef} className='pages-stage'>
                <HeaderPage/>
                {doorCountsToggle===true && <DoorCountsPage />}
                {hardwareCountsToggle===true && <HardwareCountsPage />}
                {(allDoorSheetData && doorScheduleToggle===true) && <DoorSchedulePage />}
                {hardwareScheduleToggle==true && <HardwareSchedulePage />}
                {(allDoorSheetData && boylePagesToggle===true) && <BoylePages />}
                <style>{getPageMargins()}</style>
            </div>
        </div>
        
    )
}
export default OrderComponent