import { useState, useRef, useEffect } from "react";

const SubmittalCard = ({setChosenSubmittal, jobsData, submittalInfo}) => {
//
//HOOKS
//

  //translatedJobData is used to match a submittal to its AID(job).
  //This useState contains all relevant data for the information on the cards.
  //Instead of making a new API for submittals (like I did takeoffs), I then just match the data based on AID.
  const [translatedJobData, setTranslatedJobData] = useState()

  //cardRef encapsulates the entire card wrapper. I use this to change border color, relative to the submittal's completion status.
  const cardRef = useRef()
//
//EFFECTS
//

//This useEffect fires on render.
//All it does is map over jobsData, looking for the matching AID. Once found it sets translatedJobsData to the matching job's data.
useEffect(() => {
  if (jobsData) {
    jobsData.values.map(job => {
      if(job[1] == submittalInfo[0]) {
        setTranslatedJobData(job)
        // console.log(job)
      }
    })
  }
}, [])

//This useEffect is similar to the takeoff cards'.
//It checks what the status of the submittal's completion is, and changes color relative to what is found.
useEffect(() => {
  // console.log(cardRef.current)
  if (translatedJobData) {
    if (submittalInfo[2] == "Unstarted") {
      cardRef.current.style.border = "2px solid var(--redColor)"
      // console.log(cardRef.current)
    }
    else if (submittalInfo[2] == "Incomplete") {
      cardRef.current.style.border = "2px solid var(--goldenRod)"
      // console.log(cardRef.current)
    } else {
      cardRef.current.style.border = "2px solid var(--greenColor)"
    }
  }
}, [translatedJobData])
//
//FUNCS
//

//
//RETURNS
//

//Very simple return, I won't dig into it.

if (translatedJobData) {
  console.log(translatedJobData)
  
  return(
    <div onClick={()=>setChosenSubmittal(submittalInfo)} className="card-wrapper submittals medium-text">
      <div ref={cardRef} className="card-info-wrapper image">
      <div className="card-info-wrapper submittals">
        <div className="card-info" style={{fontWeight:"bold"}}>{translatedJobData[1]}</div>
        <div className="card-info">{translatedJobData[2]} - {translatedJobData[3]} - {translatedJobData[5]}, {translatedJobData[6]}</div>
        <div className="card-info"></div>
        <div className="card-info"></div>
        <div className="card-info"></div>
        <div className="card-info">Unit Count: {submittalInfo[2]}</div>
        <div className="card-info">Submittal is: {submittalInfo[3]}</div>
      </div>
      </div>

  </div>
  )

}
  
}

export default SubmittalCard