import { useState, useEffect } from "react";
import {BiMessageAltCheck,BiMessageAltX, } from 'react-icons/bi'
import {RiLoader5Fill} from 'react-icons/ri'

const Overlay = ({setOverlay, overlayState, errorMessage}) => {
//
//HOOKS
//
//
//EFFECTS
//

//
//FUNCS
//

//
//RETURNS
//

//This simple return gives three outcomes based on the conditions, the three splits are "complete", "waiting", and "failure".
//The overlay will always return failure, if the server returns with an error, or if I manually have the server return a "failure" string.

if (overlayState == 'none') {return} else if (overlayState == 'complete') {
  return (<div className="overlay-page">
    <div className="overlay-pane-wrapper">
      <div className="overlay-pane-icon"><BiMessageAltCheck /></div>
      <div className="overlay-pane-info">Saved!</div>
      <div className="input-field button overlay" onClick={() => {setOverlay('none')}} >FINISH</div>
    </div>
  </div>
  )
} else if (overlayState == 'waiting') {
  return (<div className="overlay-page">
  <div className="overlay-pane-wrapper">
    <div className="overlay-pane-icon loading"><RiLoader5Fill /></div>
    <div className="overlay-pane-info">One Moment..</div>
  </div>
</div>)
} else if (overlayState == 'failure') {
  return (<div className="overlay-page">
    <div className="overlay-pane-wrapper">
      <div className="overlay-pane-icon failure"><BiMessageAltX /></div>
      <div className="overlay-pane-info">FAILED SAVE</div>
      <div className="overlay-pane-info fail"></div>
      <div className="overlay-pane-info fail">Try again, or contact Seb if this error persists!</div>
      <div className="input-field button overlay" onClick={() => {setOverlay('none')}} >OKAY</div>
    </div>
  </div>
  )
} else {return(<div>one moment..</div>)}



}
export default Overlay