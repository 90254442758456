import { useEffect, useState, useRef } from 'react';
import './App.css';
import Header from './Header/Header'
import StagingPage from './Header/StagingPage';
import AmpDocs from './MainComponents/AmpDocs'
import AutoFile from './MainComponents/AutoFile'
import Checks from './MainComponents/Checks'
import DocsData from './DataReports/DocsData'
import InvoiceData from './DataReports/InvoiceData'
import Invoicing from './MainComponents/Invoicing'
import Miles from './MainComponents/Miles'
import JobCard from './MainComponents/JobCard'
import Settings from './MainComponents/Settings'
import TakeoffCard from './ToolsLibraries/TakeoffCard'
import Overlay from './Overlay/Overlay'
import DocsBox from './DataReports/DocsBox';
import SubmittalApp from './Submittals/SubmittalApp';


function DashApp() {

//
//HOOKS
//

//baseUrl is the URL for the server end point, I often toggle between these two states for local server testing.
const [baseUrl, setBaseUrl] = useState('https://us-central1-amp-website-88a6f.cloudfunctions.net/')
// const [baseUrl, setBaseUrl] = useState('http://localhost:1396/')

//overlayState can be success, failure, or none. Modifying this renders the gray overlay.
const [overlayState, setOverlayState] = useState('none')

//tabState renders which page the user has decided. When its an empty string, no page appears.
const [tabState, setTabState] = useState('')

//loginState can be success or failure. If failure, the login page will overlay the entire page, rendering the site unusable.
const [loginState, setLoginState] = useState('failure')

//_____Data states are used to hold their respective data that gets fetched from the server.
const [invoicesData, setInvoicesData] = useState()
const [docsData, setDocsData] = useState()
const [checksData, setChecksData] = useState()
const [jobsData, setJobsData] = useState()
const [takeoffsData,setTakeoffsData] = useState()
const [submittalsData, setSubmittalsData] = useState()
const [vendorData,setVendorData] = useState([])
const [milesData, setMilesData] = useState(0)

//errorMessage is not currently used, but its purpose is to pass error messages from the server to the overlay.
const [errorMessage, setErrorMessage] = useState('')

//appRef is used to reference App (head elemenent) and the only time I change this referece is to change the theme color.
const appRef = useRef()

//palette reference holds the three primary colors of the page. Changing theme changes this reference.
const palette = useRef({
  a: 'rgb(122, 178, 25) ',
  b: 'rgb(210, 177, 10) ',
  c: 'rgb(250, 250, 250)'
})

//
//EFFECTS
//
useEffect(() => {
  generateJobsReport()
  generateSubmittalsLibrary()
  // generateTakeoffsLibrary()
  generateInvoiceLibrary()
  generateDocsDataLibrary()
  getTotalMiles()
  getVendors()

  //This if logic simply finds whether or not the user has been to the page or not.
  //If they have not (first condition) then the localStorage value will set to the palette reference above.
  //If they have (second condition) then the localStorage is pulled and assigned to the palette reference.
  if (localStorage.getItem("palette") === null) {
    let strungPalette = JSON.stringify(palette.current)
    console.log(strungPalette)
    localStorage.setItem('palette', strungPalette)
  } else {
    let localPalette = JSON.parse(localStorage.getItem('palette'))
    console.log(localPalette)
    palette.current = localPalette
    appRef.current.style.setProperty('--greenColor',palette.current.a)
    appRef.current.style.setProperty('--orangeColor',palette.current.b)
    appRef.current.style.setProperty('--goldenRod',palette.current.b)
    appRef.current.style.setProperty('--whiteColor', palette.current.c)
  }  
}, [])

//This effect is simply to re-render relevant data when the tabs are switched.
useEffect(() => {
  switch(tabState) {
    case 'invoiceData': {
      generateInvoiceLibrary()
    }break;
    case 'checks': {}break;
    case 'miles': {
      getTotalMiles()
    }break;
    case 'docsData': {
      generateDocsDataLibrary()
    }break;
    case 'reports': {
      generateJobsReport()
    }break;
  }
}, [tabState])


//
//FUNCS
//

//refreshDocs is not used.
const refreshDocs =()=> {
  // console.log('made it')
  // generateDocsDataLibrary()
}

//setOverlay is a simple pass-through function between any chosen page, and the overlay.
//err is a parameter that is established, but is not currently use. The errors tend to overflow and I'm too lazy to trim.
const setOverlay = (v, err) => {
setOverlayState(v)
// setErrorMessage(err)
}

//setLoginAuth is a simple pass-through function between the login page, and the app.
const setLoginAuth = (v) =>{
  setLoginState(v)
}

//changeTab handles the changing of tabs (pages)
//It also removes & sets the "active" css class that gives the highlight, so the user knows which tab they're on.
const changeTab = (v) => {
  setTabState(v)
  let tabButtons = document.getElementsByClassName('nav-button')
  for (let i = 0;i<tabButtons.length;i++){
    tabButtons[i].classList.remove('active')
  }
  let idConcat = 'nav-' + v
  document.getElementById(idConcat).classList.add('active')
}


//
//FETCHES
//

//generate__________ functions are simply the fetches to the server to pull all relevant data.
async function generateJobsReport() {
  const jobsFetch = await fetch(baseUrl + 'getJobs', {
      method: 'GET'
  })
  .then(result => result.json())
  .then(data => {
    
      data.values.shift()
      setJobsData(data)
  })
}

async function generateSubmittalsLibrary() {
  const submittalsFetch = await fetch('https://us-central1-amp-website-88a6f.cloudfunctions.net/getSubmittals', {
    method: 'GET'
  })
  .then(result => result.json())
  .then(data => setSubmittalsData(data.getSubmittalsSheet.data.values))
}

// async function generateTakeoffsLibrary() {
//   const takeoffsFetch = await fetch(baseUrl + 'getTakeoffs', {
//     method: 'GET'
//   })
//   .then(result => result.json())
//   .then(data => {
//     console.log(data)
//     setTakeoffsData(data.getTakeoffsSheet.data.values)
//   })
// }

async function generateInvoiceLibrary() {
  const invoicesFetch = await fetch(baseUrl + 'getInvoices', {
    method: 'GET'
  })
  .then(result => result.json())
  .then(data => {
    console.log(data)
    setInvoicesData((data.getInvoices.data.values).reverse())
  })
}
async function generateDocsDataLibrary() {
  const docsDataFetch = await fetch(baseUrl + 'getDocsData', {
    method: 'GET'
  })
  .then(result => result.json())
  .then(data => {
    console.log(data)
    setDocsData((data.getDocsData.data.values).reverse())
  })
}
async function getTotalMiles() {
  const milesFetch = await fetch(baseUrl + 'getTotalMiles', {
    method: 'GET'
  })
  .then(result => result.json())
  .then(data => {
    console.log(data)
    setMilesData(data.totalMiles)
  })
}
async function getVendors() {
  const vendorsFetch = await fetch("https://us-central1-amp-website-88a6f.cloudfunctions.net/getVendorData", {
    method: 'GET'
  })
  .then(result=>result.json())
  .then(data =>{
    console.log(data)
    setVendorData(data.getVendorData.data.values)
  })
}
//
//RETURNS
//

//If you've read my code before, you'll know that this is how I always write the "chosen tab" functional components.
//Simply, whatever page is chosen returns its functional component that encapsulates the entire page.
//The only exception to the line above is submittals, takeoffs, and reports.
//That is becasue they are nested inside of "submittals-wrapper" div, which is just an alternative to "page-stage".
const ChosenPageSplitter = () => {
  switch(tabState) {
    case 'invoicing':
      return(<Invoicing vendorData={vendorData} jobsData={jobsData} baseUrl={baseUrl} setOverlay={setOverlay}/>)
    case 'autoFile':
      return(<AutoFile vendorData={vendorData} jobsData={jobsData} baseUrl={baseUrl} setOverlay={setOverlay}/>)
    case 'invoiceData':
      return(<InvoiceData vendorData={vendorData} jobsData={jobsData} baseUrl={baseUrl} invoicesData={invoicesData} setOverlay={setOverlay}/>)
    case 'checks':
      return(<Checks jobsData={jobsData} baseUrl={baseUrl} checksData={checksData} setOverlay={setOverlay}/>)
    case 'miles':
      return(<Miles milesData={milesData} baseUrl={baseUrl} setOverlay={setOverlay}/>)
    case 'ampDocs':
      return(<AmpDocs jobsData={jobsData} baseUrl={baseUrl} setOverlay={setOverlay}/>)
    case 'docsData':
      return(
        <DocsBox vendorData={vendorData} refreshDocs={refreshDocs} jobsData={jobsData} baseUrl={baseUrl} docsData={docsData} setOverlay={setOverlay} />
      )
    case 'submittals':
      return(
        <SubmittalApp jobsData={jobsData} data={submittalsData} />
      )
    case 'takeoffs':
      return(
        <div className="submittals-wrapper">
          {takeoffsData.map(takeoff => <TakeoffCard takeoffInfo={takeoff} key={Math.random()} />)}
        </div>
      )
    case 'reports':
      return(
        <div className='submittals-wrapper'>
          {jobsData.values.map((job, index) => <JobCard setOverlay={setOverlay} jobInfo={job} index={index} baseUrl={baseUrl} key={Math.random()} />)}
        </div>
      )
    case 'settings':
      return(<Settings appRef={appRef} baseUrl={baseUrl} setOverlay={setOverlay}/>)
  }
}

//The big one.. App return.
  return (
    <div ref={appRef} className="App">
      <Overlay errorMessage={errorMessage} setOverlay={setOverlay} overlayState={overlayState} />
      <Header changeTab={changeTab} loginState={loginState} baseUrl={baseUrl} setLoginAuth={setLoginAuth} setOverlay={setOverlay}/>
      <StagingPage />
      <ChosenPageSplitter />
    </div>
  );
}

export default DashApp;