import { useState, useEffect, useRef } from "react"
import logo from './watermark.png'
import './App.css'
import HardwareBlockComponent from "./HardwareBlockComponent"
import HardwareLineComponent from "./HardwareLineComponent"
import HardwareSmallBlockComponent from "./HardwareSmallBlockComponent"
import HardwareTinyBlockComponent from "./HardwareTinyBlockComponent"

const PrintCondensed = ({doorCode, hardwareCodes, hardwareRepo, doorImage, hardwareToggle, index}) => {
    // console.log(doorImage[0])
    // console.log(hardwareCodes)
    // console.log(hardwareRepo)


    const [matchingHardwareItems, setMatchingHardwareItems] = useState([0,0])
    const [matchingDoorImage, setMatchingDoorImage] = useState(doorImage[0])
    
    const imgURLRef = useRef()

    useEffect(() => {
    //   console.log(doorCode)
        let hardwareCodesCopy = [...hardwareCodes]
        let hardwareRepoCopy = [...hardwareRepo]
        let hardwareCodesArray = hardwareCodesCopy[0]
        try {
            // console.log('Match!')
            // console.log(hardwareCodesArray)
            let arrayOfHardwares = []
            for (let i=0;i<hardwareCodesArray.length;i++) {
                const matchedItemCode = hardwareRepoCopy.filter(piece => hardwareCodesArray[i] == piece[0])
                arrayOfHardwares.push(matchedItemCode)
            }
            arrayOfHardwares.shift()
            // console.log(doorCode[0], doorCode[10], arrayOfHardwares)
            setMatchingHardwareItems(arrayOfHardwares)
            setMatchingDoorImage(doorImage[0][1])
        } catch(err) {
            console.error('This door ' + doorCode[0] + ' does not have any matching hardware codes! Or does not have an image match!')
            // console.error(err)
        }
    }, [])


const LineOrBlockSplitter =() => {
    if (hardwareToggle === true) {
        if (matchingHardwareItems.length > 12) {
            return(
            <div className="page-row-wrapper hardware condensed">
                <div className="hardware-line-wrapper">
                    <div className="info-cell hardware header">MNFR</div>
                    <div className="info-cell hardware header">ITEM DESC</div>
                    <div className="info-cell hardware header">MODEL#</div>
                    <div className="info-cell hardware header">FINISH</div>
                    <div className="info-cell hardware header">QTY</div>fontSize:12,
                </div>
                {matchingHardwareItems && matchingHardwareItems.map(itemData => <HardwareLineComponent itemData={itemData[0]} key={Math.random()}/>)}
            </div>
            )
        } else if (matchingHardwareItems.length > 10) {
            return (
                <div className="page-row-wrapper hardware">
                    {matchingHardwareItems && matchingHardwareItems.map(itemData => <HardwareTinyBlockComponent itemData={itemData[0]} key={Math.random()}/>)}
                </div>
            )
        } else if (matchingHardwareItems.length > 8) {
            return (
                <div className="page-row-wrapper hardware">
                    {matchingHardwareItems && matchingHardwareItems.map(itemData => <HardwareSmallBlockComponent itemData={itemData[0]} key={Math.random()}/>)}
                </div>
            )
        } else {
            return (
                <div className="page-row-wrapper hardware">
                    {matchingHardwareItems && matchingHardwareItems.map(itemData => <HardwareBlockComponent itemData={itemData[0]} key={Math.random()}/>)}
                </div>
            )
        }
    } else {return}
}


//<div style={{width: '0%'}} className="info-cell"></div>
    return (
            // <div className="body-wrapper condensed">
            //     <div className="page-row-wrapper condensed">
            //         <div className="door-info-wrapper condensed">
            //             <div className="info-cell-stack condensed">
            //                 <div className="info-cell stacked condensed" style={{fontWeight: 'bold', width:"10%"}}>{doorCode[0]}</div>
            //                 <div className="info-cell stacked condensed" style={{width: "15%"}}>{doorCode[2]}</div>
            //                 <div className="info-cell stacked condensed" style={{width: ""}}>RO: {doorCode[19]}</div>
            //             </div>
            //             <div className="info-cell-stack condensed">
            //             </div>
            //             <div className="info-cell-stack condensed">
            //                 <div className="info-cell stacked condensed">•Bore: {doorCode[12]}</div>
            //             </div>
            //         </div>
                    
            //     </div>
            //     <LineOrBlockSplitter />
                
            // </div>
        <>
          <tr className="totals-row condensed" >
            <td className="totals-data condensed" style={{fontSize:14,fontWeight:"bold", borderBottom: (doorCode[17] == "-" || doorCode[17] == "") ? "1px solid black" : "none" ,borderTop: "1px solid black", textAlign:"center"}}>{doorCode[0]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[2]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[9]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[3]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[11]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[12]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[4]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[5]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[6]}x{doorCode[7]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[8]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[10]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[19]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[15]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[18]}</td>
            <td className="totals-data condensed" style={{fontSize:14,borderBottom: "1px solid black", borderTop: "1px solid black", borderLeft: "none"}}>{doorCode[13]}</td>

          </tr>


            {(doorCode[17] !== "-" && doorCode[17] !== "") && (
              <tr className="totals-row condensed" >
                <td className="totals-data condensed" style={{fontWeight:"bold", borderBottom: "1px solid black", borderTop: "none"}}></td>
                <td className="totals-data condensed" colSpan="14" style={{fontSize: 14, borderBottom: "1px solid black", borderTop: "none", borderLeft: "none"}}>Notes: &nbsp;{doorCode[17]}</td>
              </tr>
            )}
            {(doorCode[17] == "-" || doorCode[17] == "") && (
            <tr className="totals-row condensed" >
              {/* <td className="totals-data condensed" colSpan="14" style={{borderBottom: "2px solid black", borderLeft: "none"}}></td> */}
            </tr>
            )}
        </>
    )
}
export default PrintCondensed