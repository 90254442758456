import { useState, useEffect, useRef } from "react";

//NO COMMENTS NEEDED.
//IF CONFUSED ABOUT THE METHODOLOGY HERE, REFER TO Invoicing.js

const Miles = ({baseUrl,setOverlay,milesData}) => {
//
//HOOKS
//
const milesFormRef = useRef()
//
//EFFECTS
//

//
//FUNCS
//
const submitMileage = async(e, val) => {
  e.preventDefault()
  setOverlay('waiting')
  let parcelFormData = new FormData()
  parcelFormData.append('miles', milesFormRef.current[0].value)
  parcelFormData.append('date', milesFormRef.current[1].value)
  
  const res = await fetch(baseUrl + 'miscOps/postMiles', {
    method: 'POST',
    body: parcelFormData
  })
  .then((res) => {
    if (res.ok) {return res.json()} throw new Error('Bad Request')
  })
  .then(data => {
    console.log(data)
    setOverlay(data.response)
  })
  .catch((error) => [
    setOverlay('failure', error)
  ])  
}

//
//RETURNS
//
return(
<form ref={milesFormRef} className="page-stage small">
  <div className="mediumish-text">Total Miles Driven:</div>
<div className="large-text" style={{color: 'var(--goldenRod)'}} >{milesData}</div>
<hr></hr>
  <div className="mediumish-text">Enter Miles & Date</div>
  <div style={{height: '2vh'}}></div>
  <div className="field-row">
    <div className="field-column small">
    <input className="input-field" type="number"></input>
    </div>
    
    <div className="field-column" style={{width: '55%'}}>
      <input className="input-field" type="date"></input>
    </div>
      
      
    </div>
    <div style={{height: '2vh'}}></div>
    <button className="input-field button" onClick={submitMileage}>SUBMIT</button>
    
</form>
)
}
export default Miles