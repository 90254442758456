import { useState, useEffect, useRef } from "react";
import BillDoc from '../Documents/BillDoc'
import ChangeOrderDoc from "../Documents/ChangeOrderDoc";
import OrderDoc from '../Documents/OrderDoc'
import PChangeOrderDoc from '../Documents/PChangeOrderDoc'
import QuoteDoc from '../Documents/QuoteDoc'
import WorkOrderDoc from "../Documents/WorkOrderDoc";
import QuickBid from "../Documents/QuickBid";

// import {BiWrench, BiDollarCircle, BiCart, BiMessageDots, BiMessageError,BiSpreadsheet} from 'react-icons/bi'

//Often in this component you'll see the word "bill" for a lot of variables.
//This is because in version 1.0, AmpDocs were called "Bills"
//Replace Bills with AMPDocs, and it will make this component easier to understand.
//The only exception is the BillDoc func component, because that is an actual billing document.

const AmpDocs = ({jobsData,baseUrl,setOverlay}) => {
//
//HOOKS
//

//AMPInvoiceNum is the current AMP-Docs number. i.e. when a user generates a new document, AMPInvoiceNum is incremented by 1.
const [AMPInvoiceNum, setAMPInvoiceNum] = useState(99)

//billTypeState contains the chosen doc-type from the select option below.
//It is used to re-render so the correct document is chosen, this state is switched over below, to display the proper func component.
const [billTypeState, setBillTypeState] = useState('')

//billSelectRef is a simple reference to the select element below, so we can capture the doc-type option chosen.
const billSelectRef = useRef()
//
//EFFECTS
//

//Every time this component mounts, it runs fetchBillTag(), which is commented on below.
useEffect(() => {
  fetchBillTag()
}, [])
//
//FUNCS
//

//updateAMPTag is passed down as a prop. When run, the component entirely re-renders, due to the assignment of empty strings,
// and re-captures the AMPTag number (AMP-Docs number).
const updateAMPTag =()=> {
  fetchBillTag()
  setBillTypeState('')
  setBillTypeState(billSelectRef.current.value)
}

//updateDocType runs when the select option changes, this is to render the correct doc-type page.
const updateDocType = () => {
  setBillTypeState(billSelectRef.current.value)
}
//
//FETCH
//

//fetchBillTag fetches a simple integer from the API.
//This integer is then stored in AMPInvoiceNum.
const fetchBillTag = async() => {
  await fetch(baseUrl + 'getBillingNum', {
    method: 'GET'
  })
  .then(result => result.json())
  .then(data => {
    setAMPInvoiceNum(data.billNum)
  })
}





//
//RETURNS
//


//DocSplitter is a simple switch that renders the matching doc-type form field to what the user chose.
const DocSplitter = () => {
  if (billTypeState) {
  switch(billTypeState) {
    case '':
      return(<div>Choose a document to begin!</div>)
    case 'BillDoc':
      return(<BillDoc jobsData={jobsData}baseUrl={baseUrl}setOverlay={setOverlay}AMPInvoiceNum={AMPInvoiceNum}updateAMPTag={updateAMPTag}/>)
    case 'QuoteDoc':
      return(<QuoteDoc jobsData={jobsData}baseUrl={baseUrl}setOverlay={setOverlay}AMPInvoiceNum={AMPInvoiceNum}updateAMPTag={updateAMPTag}/>)
    case 'OrderDoc':
      return(<OrderDoc jobsData={jobsData}baseUrl={baseUrl}setOverlay={setOverlay}AMPInvoiceNum={AMPInvoiceNum}updateAMPTag={updateAMPTag}/>)
    case 'ChangeOrderDoc':
      return(<ChangeOrderDoc jobsData={jobsData}baseUrl={baseUrl}setOverlay={setOverlay}AMPInvoiceNum={AMPInvoiceNum}updateAMPTag={updateAMPTag}/>)
    case 'PChangeOrderDoc':
      return(<PChangeOrderDoc jobsData={jobsData}baseUrl={baseUrl}setOverlay={setOverlay}AMPInvoiceNum={AMPInvoiceNum}updateAMPTag={updateAMPTag}/>)
    case 'WorkOrderDoc':
      return(<WorkOrderDoc jobsData={jobsData}baseUrl={baseUrl}setOverlay={setOverlay}AMPInvoiceNum={AMPInvoiceNum}updateAMPTag={updateAMPTag} />)
    case 'QuickBid':
      return(<QuickBid jobsData={jobsData}baseUrl={baseUrl}setOverlay={setOverlay}AMPInvoiceNum={AMPInvoiceNum}updateAMPTag={updateAMPTag} />)
  }
  }
}

//Not too much to explain here, generic page stage with a selection option & the Amp-Docs number!

  return(
    <div className="page-stage medium">
      <div className="field-row">
      <div className="input-field mediumish-text" style={{border: '1px solid black', boxSizing: 'border-box', lineHeight: '4vh', backgroundColor: 'var(--whiteColor)'}}>AMP-{AMPInvoiceNum}</div>
      <select onChange={updateDocType} className="input-field medium-text" ref={billSelectRef}>
        <option value="">Choose Doc</option>
        <option value="BillDoc">Bill</option>
        <option value="QuoteDoc">Quote</option>
        <option value="QuickBid">Quick Quote</option>
        <option value="OrderDoc">Order</option>
        <option value="WorkOrderDoc">Work Order</option>
        <option value="ChangeOrderDoc">C.O.</option>
        <option value="PChangeOrderDoc">P.C.O.</option>
        
      </select>
      </div>
      
      <DocSplitter />
    </div>
  )
}
export default AmpDocs