import { useState, useEffect, useRef } from "react";
import DocHeader from "./DocHeader";
import DocFooter from './DocFooter';
import {BiWrench} from 'react-icons/bi'
import {Footer,BorderStyle, AlignmentType, TextRun, Packer, Table, TableRow, TableCell, Paragraph, ImageRun, Document, VerticalAlign, HeightRule, FooterWrapper} from "docx"
import { saveAs } from "file-saver";
import { Buffer } from "buffer";

const WorkOrderDoc = ({docGenPost,jobsData,baseUrl,setOverlay,AMPInvoiceNum,updateAMPTag}) => {
//headerFormRef references the header form element that is pulled from DocHeader.js.
const headerFormRef = useRef()

//bodyFormRef references the body form element that is contained in the return section below.
const bodyFormRef = useRef()

//footerFormRef references the footer form element that is pulled from DocFooter.js
const footerFormRef = useRef()

const inputsRef = useRef([])

const handleKeyPress = (e, index) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    if (index + 1 < inputsRef.current.length) {
      inputsRef.current[index + 1].focus();
    }
  }
};

const submitForm = async(e) => {
  e.preventDefault()
  if (headerFormRef.current[1].value == '' || headerFormRef.current[2].value == '' || headerFormRef.current[3].value == '') {return window.alert('PLEASE FILL IN ALL FIELDS')}
  if (bodyFormRef.current[0].value == '' || bodyFormRef.current[1].value == '' || bodyFormRef.current[2].value == '' || bodyFormRef.current[3].value == '' || bodyFormRef.current[4].value == '' || bodyFormRef.current[5].value == '' || bodyFormRef.current[6].value == '') {return window.alert('PLEASE FILL IN ALL FIELDS')}
  let parcelFormData = new FormData()
  parcelFormData.append('docuType', 'docBill')
  parcelFormData.append('DocID', 'AMP-' + AMPInvoiceNum)
  parcelFormData.append('AID', headerFormRef.current[0].value)
  parcelFormData.append('date', headerFormRef.current[1].value)
  parcelFormData.append('total', headerFormRef.current[2].value)
  parcelFormData.append('type', headerFormRef.current[3].value)
  parcelFormData.append('contractorName', bodyFormRef.current[0].value)
  parcelFormData.append('contractorCompany', bodyFormRef.current[1].value)
  parcelFormData.append('contractorEmail', bodyFormRef.current[2].value)
  parcelFormData.append('jobsiteName', bodyFormRef.current[3].value)
  parcelFormData.append('jobsiteAddress', bodyFormRef.current[4].value)
  parcelFormData.append('jobsiteCityState', bodyFormRef.current[5].value)
  parcelFormData.append('description', bodyFormRef.current[6].value)
  parcelFormData.append('signature', footerFormRef.current[0].value)
  //
  //Parcel data is sent to backend at the end of the DOCX piece
  
  //docSave/bill
  for (const value of parcelFormData.values()) {
    console.log(value)
  }
//
//DOCX
//
let imgurLogo = await fetch ('https://i.imgur.com/tSIfb53.png')
let imgurBuffer = Buffer.from(await imgurLogo.arrayBuffer())

const logoTopper = new Paragraph({children: [
  new ImageRun({
    data: imgurBuffer,
    transformation: {
      width: 204,
      height: 184,
    }
  })
],alignment: AlignmentType.CENTER })

const dateData = new Date();
let dateFormatted = dateData.getMonth() + 1 + '/' + dateData.getDate() + '/' + dateData.getFullYear();

const headerTable = new Table({
  rows: [
    new TableRow({children:[
      new TableCell({
        borders: {
          top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        },children:[
          new Paragraph({children:[
            new TextRun({
              text: 'PO #:  ',
              font: "Arial",
              size: 22,
            }),
            new TextRun({
              text: 'AMP-' + AMPInvoiceNum,
              bold: true,
              font: "Arial",
              size: 24,

            })
          ],
          alignment: AlignmentType.CENTER,
          }),
      ],verticalAlign: VerticalAlign.CENTER,
    }),
    new TableCell({
      borders: {
        top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      },children:[
        new Paragraph({children:[
          new TextRun({
            text: 'WORK ORDER',
            bold: true,
            font: "Arial",
            size: 24,

          })
        ],
        alignment: AlignmentType.CENTER,
        }),
    ],verticalAlign: VerticalAlign.CENTER,
  }),
      new TableCell({borders: {
        top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        right: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      },children:[
          new Paragraph({children:[
            new TextRun({
              text: 'Date:  ',
              font: "Arial",
              size: 22,

            }),
            new TextRun({
              text: dateFormatted,
              bold: true,
              font: "Arial",
              size: 24,

            })
          ],
          alignment: AlignmentType.CENTER,
          })
      ],
      verticalAlign: VerticalAlign.CENTER,
    })
    ]})
  ],
  width: 0, // AUTO
  columnWidths: [3000, 3100, 3000], // total page width is 9638 DXA for A4 portrait

// indent: {
//     size: 600,
//     type: WidthType.DXA,
// }
})
const addressTable = new Table({rows:[
  new TableRow({children:[
  new TableCell({borders: {top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'}},children:[
      new Paragraph({children:[
        new TextRun({
          text: 'Contractor Info: ',
          bold: true,
          font: "Arial",
          size: 20,
        }),
      ],
      alignment: AlignmentType.LEFT,
      }),
      new Paragraph({children:[
        new TextRun({
          text: bodyFormRef.current[0].value,
          font: "Times New Roman",
          size: 20,
        }),
      ],
      indent: {left: 300},
      alignment: AlignmentType.LEFT,
      }),
      new Paragraph({children:[
        new TextRun({
          text: bodyFormRef.current[1].value,
          font: "Times New Roman",
          size: 20,
        }),
      ],
      indent: {left: 300},
      alignment: AlignmentType.LEFT,
      }),
      new Paragraph({children:[
        new TextRun({
          text: bodyFormRef.current[2].value,
          font: "Times New Roman",
          size: 20,
        }),
      ],
      indent: {left: 300},
      alignment: AlignmentType.LEFT,
      }),
    ],
    verticalAlign: VerticalAlign.CENTER,
  }),
  new TableCell({borders: {top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'}},children:[
    new Paragraph({children:[
      new TextRun({
        text: 'Jobsite Info: ',
        bold: true,
        font: "Arial",
        size: 20,
      }),
    ],
    alignment: AlignmentType.LEFT,
    }),
    new Paragraph({children:[
      new TextRun({
        text: bodyFormRef.current[3].value,
        font: "Times New Roman",
        size: 20,
      }),
    ],
    indent: {left: 300},
    alignment: AlignmentType.LEFT,
    }),
    new Paragraph({children:[
      new TextRun({
        text: bodyFormRef.current[4].value,
        font: "Times New Roman",
        size: 20,
      }),
    ],
    indent: {left: 300},
    alignment: AlignmentType.LEFT,
    }),
    new Paragraph({children:[
      new TextRun({
        text: bodyFormRef.current[5].value,
        font: "Times New Roman",
        size: 20,
      }),
    ],
    indent: {left: 300},
    alignment: AlignmentType.LEFT,
    }),
  ],
  verticalAlign: VerticalAlign.CENTER,
}),
], height: {value: 1000, rule: HeightRule.EXACT} })
], width: 0, columnWidths: [4550, 4550]

})

const smallSpacer = new Paragraph({children:[
  new TextRun({
    text: '',
    size: 20,
    color: 'FFFFFF',
  })
]
})

const spacerTable = new Table({rows:[
  new TableRow({children:[
    new TableCell({borders: {
      top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      right: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
    },children:[
      new Paragraph({children: [
        new TextRun({
          text: 'Add any details here..'
        })
      ]})
    ]})
  ], height: {value: 1500, rule: HeightRule.EXACT}})
], width:0, columnWidths:[9100,0] })

const infoTable = new Table({rows: [
  new TableRow({children:[
    new TableCell({children:[
      new Paragraph({children:[
        new TextRun({
          text: 'CREATED BY'
        })
      ],alignment: AlignmentType.CENTER}),
      new Paragraph({children:[
        new TextRun({
          text: '',
          size: 8,
          color: 'FFFFFF',
        })
      ]
      }),
      new Paragraph({children:[
        new TextRun({
          text: footerFormRef.current[0].value,
          bold: true,
          size: 24,
        })
      ],alignment: AlignmentType.CENTER})
    ]}),
    new TableCell({children:[
      new Paragraph({children:[
        new TextRun({
          text: 'JOB #'
        })
      ],alignment: AlignmentType.CENTER}),
      new Paragraph({children:[
        new TextRun({
          text: '',
          size: 8,
          color: 'FFFFFF',
        })
      ]
      }),
      new Paragraph({children:[
        new TextRun({
          text: headerFormRef.current[0].value,
          bold: true,
          size: 24,
        })
      ],alignment: AlignmentType.CENTER})
    ]}),
    new TableCell({children:[
      new Paragraph({children:[
        new TextRun({
          text: 'TERMS'
        })
      ],alignment: AlignmentType.CENTER}),
      new Paragraph({children:[
        new TextRun({
          text: '',
          size: 8,
          color: 'FFFFFF',
        })
      ]
      }),
      new Paragraph({children:[
        new TextRun({
          text: "Must complete all work!",
          bold: true,
          size: 24,
        })
      ],alignment: AlignmentType.CENTER})
    ]}),
    
  ], height: {value: 800, rule: HeightRule.EXACT}})
], width: 0, columnWidths: [3100, 3100, 2900]})


const emptyTable = new Table({rows:[
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '#',
        font: 'Arial',
        bold: true,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ],verticalAlign: VerticalAlign.CENTER,}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: 'DESCRIPTION',
        font: 'Arial',
        bold: true,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ],verticalAlign: VerticalAlign.CENTER,}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: 'QTY',
        font: 'Arial',
        bold: true,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ],verticalAlign: VerticalAlign.CENTER,}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: 'UNIT PRICE',
        font: 'Arial',
        bold: true,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ],verticalAlign: VerticalAlign.CENTER,}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: 'SUM PRICE',
        font: 'Arial',
        bold: true,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ],verticalAlign: VerticalAlign.CENTER,}), 
], height: {value: 400, rule: HeightRule.EXACT}}),

new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '1',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '$',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
]}),

]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.LEFT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.RIGHT})
  ]}), 
]}),
],width: 0, columnWidths: [500, 5000, 700, 1400, 1500]})

const totalClone = +headerFormRef.current[2].value


const totalTable = new Table({rows: [
  new TableRow({children:[
    new TableCell({borders: {
      top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
    },children:[
      new Paragraph({children:[
        new TextRun({
          text: 'TOTAL CONTRACT: ',
          bold: true,
          size: 24,
        })
      ],alignment: AlignmentType.RIGHT})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text:totalClone.toLocaleString("en-US", {style:"currency", currency:"USD"}),
        bold: true,
        size: 24,
        })
      ],alignment: AlignmentType.RIGHT})
    ]})
  ]})
],width: 0, columnWidths: [7600, 1500]})

const footerMessage = new Footer({children:[
  new Paragraph({children:[
    new TextRun({
      text: 'THANK YOU FOR YOUR BUSINESS!',
      italics: true,
      font: 'EB Garamond',
      size: 24,
    })
  ],alignment: AlignmentType.CENTER}),
]})

const billDoc = new Document({
  sections: [
      {
          properties: {
            page: {
              margin: {
                top: 400
              }
            }
          },
          footers:{
            default: footerMessage
          },
          children: [
            logoTopper,
            smallSpacer,
            headerTable,
            smallSpacer,
            addressTable,
            smallSpacer,
            spacerTable,
            smallSpacer,
            infoTable,
            smallSpacer,
            emptyTable,
            smallSpacer,
            totalTable,
            smallSpacer
          ],
      },
  ],
});

Packer.toBlob(billDoc).then((blob) => {
  console.log(blob);
  saveAs(blob, 'AMP-' + AMPInvoiceNum + ' Work Order');
  console.log("Document created successfully");
});
const res = await fetch(baseUrl + 'docSave/workOrder', {
  method: 'POST',
  body: parcelFormData,
})
.then((res) => {
  if (res.ok) {return res.json()} throw new Error('Bad Request')
})
.then(data => {
  console.log(data)
  setOverlay(data.response)
  updateAMPTag()
})
.catch((error) => [
  setOverlay('failure', error)
])

//END OF SUBMIT FORM
}


return(
  <div>
  <div className="large-text orange-icon"><BiWrench /></div>
    <DocHeader headerFormRef={headerFormRef} jobsData={jobsData}/>
    <hr></hr>
    <form ref={bodyFormRef}>
      <div className="field-row">
        <div className="field-column">
          <label htmlFor="billToField" className="medium-text">Contractor Name:</label>
          <input name="billToField" className="input-field" type="text"
                   onKeyDown={(e) => handleKeyPress(e, 0)} 
                   ref={(el) => inputsRef.current[0] = el} 
                 />
          <label htmlFor="billAddressField" className="medium-text">Contractor Company:</label>
          <input name="billAddressField" className="input-field" type="text"
                   onKeyDown={(e) => handleKeyPress(e, 1)} 
                   ref={(el) => inputsRef.current[1] = el} 
                 />
          <label htmlFor="billCityStateField" className="medium-text">Contractor Email:</label>
          <input name="billCityStateField" className="input-field" type="text"
                   onKeyDown={(e) => handleKeyPress(e, 2)} 
                   ref={(el) => inputsRef.current[2] = el} 
                 />
        </div>
        <div className="field-column">
          <label htmlFor="shipNameField" className="medium-text">Jobsite Name:</label>
          <input name="shipNameField" className="input-field" type="text"
                   onKeyDown={(e) => handleKeyPress(e, 3)} 
                   ref={(el) => inputsRef.current[3] = el} 
                 />
          <label htmlFor="shipAddressField" className="medium-text">Jobsite Address:</label>
          <input name="shipAddressField" className="input-field" type="text"
                   onKeyDown={(e) => handleKeyPress(e, 4)} 
                   ref={(el) => inputsRef.current[4] = el} 
                 />
          <label htmlFor="ShipCityStateField" className="medium-text">Jobsite City,State:</label>
          <input name="ShipCityStateField" className="input-field" type="text"
                   onKeyDown={(e) => handleKeyPress(e, 5)} 
                   ref={(el) => inputsRef.current[5] = el} 
                 />
        </div>
      </div>
      <hr></hr>
      <label htmlFor="descriptionField" className="medium-text">Brief & Detailed Description:</label>
      <textarea name='descriptionField' className="description-field"
               onKeyDown={(e) => handleKeyPress(e, 6)} 
               ref={(el) => inputsRef.current[6] = el} 
             />
    </form>
    <DocFooter submitForm={submitForm} footerFormRef={footerFormRef}/>
  </div>
    )

}

export default WorkOrderDoc