import { useState, useEffect } from "react";
import Login from "./Login";
import {BiPrinter,BiPaperPlane,BiCreditCardFront,BiBot,BiWrench,BiDoorOpen, BiPackage, BiBarChartAlt2,BiBox,BiBuildings,BiArchive,BiCloud,BiCycling,BiFile } from "react-icons/bi";

const Header = ({changeTab,setLoginAuth,loginState,baseUrl,setOverlay}) => {
//
//HOOKS
//

//
//EFFECTS
//

//
//FUNCS
//

//
//RETURNS
//


//This return isn't all that complex, but I'll go over it in detail since I use this method quite often.
//This is the navigation bar at the top of the page, just in case you somehow forgot.
//Each button here (nav-button class) has its own symbol and text.
//When you click on the button wrapper, it fires "changeTab" and passes the parameter to that func; i.e. "invoicing" or "miles".
//The only 2 exceptions to this rule are: the #nav-drive element, which direct the user to google drive, and the #nav-logout which sets the loginAuth state to 'failure'

  if (loginState == 'success') {
    return (
      <header className="app-header">
        <div className="nav-container">
            <div id='nav-invoicing' onClick={()=>changeTab('invoicing')}className='nav-button'>
              <div className="nav-icon"><BiFile /></div>
              <div className="nav-header">INVOICING</div>
            </div>
            <div id='nav-autoFile' onClick={()=>changeTab('autoFile')} className='nav-button'>
              <div className="nav-icon"><BiBot /></div>
              <div className="nav-header">INVOICE-BOT</div>
            </div>
            <div id='nav-invoiceData' onClick={()=>changeTab('invoiceData')}className='nav-button'>
            <div className="nav-icon"><BiBox /></div>
              <div className="nav-header">INVOICES</div>
            </div>
            <div id='nav-ampDocs' onClick={()=>changeTab('ampDocs')} className='nav-button'>
            <div className="nav-icon"><BiPrinter /></div>
              <div className="nav-header">AMP-DOCS</div>
            </div>
            <div id='nav-submittals' onClick={()=>changeTab('submittals')} className='nav-button'>
            <div className="nav-icon"><BiPackage /></div>
              <div className="nav-header">SUBMITTALS</div>
            </div>
            <div id='nav-reports' onClick={()=>changeTab('reports')} className='nav-button'>
            <div className="nav-icon"><BiBarChartAlt2 /></div>
              <div className="nav-header">JOBS-DATA</div>
            </div>
            <div id='nav-settings' onClick={()=>changeTab('settings')} className='nav-button'>
            <div className="nav-icon"><BiWrench /></div>
              <div className="nav-header">SETTINGS</div>
            </div>
            <div id='nav-logout' onClick={()=>setLoginAuth('failure')} className='nav-button'>
            <div className="nav-icon"><BiDoorOpen /></div>
              <div className="nav-header">LOG-OUT</div>
            </div>
          </div>
      </header>
    );
  } else {
    return(
      <Login setLoginAuth={setLoginAuth} baseUrl={baseUrl} setOverlay={setOverlay}/>
    )
  }

}
export default Header