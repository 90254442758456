import '../styles.css'
import emailPic from './email.jpg'

const TabContact = () => {
    return (
        <div className='tab-wrapper'>
            <div className='header-image-wrapper'>
                <img className='header-image' src={emailPic}></img>
            </div>
            <div className='tab-body-wrapper'>
                <h1 className='tab-title'>How Can We Help You?</h1>
                <h3 className='tab-header'>Get A Quote</h3>
                <p className='tab-body'>Need your buildings' architectural millwork products quoted? You will be shocked how fast we can get a quote completed for you!
                 Simply email <a href="mailto:mike@ampconceptsllc.com">mike@ampconceptsllc.com</a> and CC <a href='mailto:sebastian@ampconceptsllc.com'>sebastian@ampconceptsllc.com</a>. You will hear back from us shortly thereafter!
                 Please note: We do not work on single-familiy residential homes.
                </p>
                <h3 className='tab-header'>Send Us A Bill</h3>
                <p className='tab-body'>You can do so by sending your invoice to <a href='mailto:accounts@ampconceptsllc.com'>accounts@ampconceptsllc.com</a>
                </p>
                <h3 className='tab-header'>Work For Us (Subcontractor)</h3>
                <p className='tab-body'>We are always looking for hard working and honest subcontractors! Please email <a href='mailto:mike@ampconceptsllc.com'>mike@ampconceptsllc.com</a>
                 with the name of your company, size of your crew, and the amount of vans you may have.
                </p>
                <h3 className='tab-header'>Technical Questions</h3>
                <p className='tab-body'>If the need arises where you have a technical question about our website, software, or anything else computer related,
                please email <a href='mailto:sebastian@ampconceptsllc.com'>sebastian@ampconceptsllc.com</a>
                </p>
                
            </div>
        </div>
    )
}
export default TabContact