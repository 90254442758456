import { useState, useEffect } from "react";

const AIDSelect = ({jobsData, chosenJobRef, filterType, defaultAID}) => {

//
//HOOKS
//

//jobsInfo state is a bucket for jobsData (which are all the jobs' information pulled from the API).
//I cannot map over jobsData, because defaultAID needs to be at the top of the list, I'll go into greater detail below.
const [jobsInfo, setJobsInfo] = useState([{}])

//matchingJobInfo is the state that contains information about the defaultAID.
//i.e. if an invoice is for A05, the user needs A05 to be the default option for the <select> object.
//This is necessary so the user won't have to manually reset the AID every time they edit, and reduces error of the wrong AID being assigned every edit.
const [matchingJobInfo, setMatchingJobInfo] = useState([])
//
//EFFECTS
//

//every time jobsData changes, which is almost never, this effect runs.
//First we establish a clone of JobsData.values by deconstructing it, this returns an array of arrays.
//Second we map over that clone, looking for a job whos AID string (job[1]) == the defaultAID string, which would be 'A05' for example.
//Third we assign the match (because there can only be one) to the matchingJobInfo state.
useEffect(() => {
  if (jobsData) {
    let jobsDataClone = [...jobsData.values]
    let matchingJob = []
    jobsDataClone.map((job, index) => {
      if (job[1] == defaultAID) {
        matchingJob.push(job)
        jobsDataClone.splice(index, 1)
      }
    })
    setMatchingJobInfo(matchingJob.flat())
    setJobsInfo(jobsDataClone)
  }
  
}, [jobsData])

//
//FUNCS
//

//
//RETURNS
//

//NOTE:
//REMOVE THE IF CONDITIONS ON THE FILTER, I NO LONGER USE THIS METHOD OF FILTERING!!
//THE defaultValue of the <select> element PROBABLY NEEDS TO BE REMOVED, TEST BEFORE REMOVING, BUT I'M SURE THIS DOESN'T WORK!!

//To begin, the select element has a reference, which is passed as a prop up and out of this component,
//that is because I need to encapsulate the chosen option in different components to append the option to a FormData() (reference any form that requires AID)

//Next the option element has the value of whatever job is matched as the "default", which is passed from the parent func component.
//for example, if matchinJobInfo[1] == 'A05', then it would show all information for A05 (Deugen Plainfield)
//but if the matchingJobInfo[1] == 'ALL', then it would show the column header titles, i.e. "Job Name" vs "Deugen Plainfield". the AID 'ALL' is the first row of the jobs API table.
//ALL is used as the default value for all non-edit fields (invoice filing, document generating, etc).

//Below that is the mapping of jobsInfo, which by now doesn't have the 'defaultAID' in the array.
//So the mapping will happen to all jobs, except whichever matches the defaultAID.
//This mapping simply returns <option> elements with relevant job information.
//This func component is one of my proudest! :)

if (matchingJobInfo.length > 0) {
  if (filterType == 'invoiceFilter') {

  } else if (filterType == 'docsFilter') {
  
  } else {
    return(
      <select name='aidSelect' ref={chosenJobRef} defaultValue={defaultAID} className='input-field'>
        <option value={matchingJobInfo[1]}>{matchingJobInfo[1]} - {matchingJobInfo[2]} {matchingJobInfo[3]}</option>
        {
          jobsInfo.map(job => {
            if (job[1] != 'ALL') {return(<option value={job[1]} key={Math.random()}>{job[1]} - {job[2]} {job[3]}</option>)}
              
            })
            
        }
      </select>
    )
  }
}


}

export default AIDSelect