import { useState, useEffect, useRef } from "react";

const TakeoffCard = ({takeoffInfo}) => {
//
//HOOKS
//

//cardRef encapsulates each takeoff card wrapper.
//The only reason I've assigned each card a reference is so I can change the coloring of the border to show the takeoff status.
const cardRef = useRef()
//
//EFFECTS
//

//This effect is what changes the border of each takeoff card.
//Based on the if logic below, a takeoff can either be "Unstarted", "Incomplete", or if it isn't either of those things, the border will show green.

useEffect(() => {
  console.log(cardRef.current)
  if (takeoffInfo) {
    if (takeoffInfo[5] == "Unstarted") {
      cardRef.current.style.border = "2px solid var(--redColor)"
      console.log(cardRef.current)
    }
    else if (takeoffInfo[5] == "Incomplete") {
      cardRef.current.style.border = "2px solid var(--goldenRod)"
      console.log(cardRef.current)
    } else {
      cardRef.current.style.border = "2px solid var(--greenColor)"
    }
  }
    
}, [takeoffInfo])

//
//FUNCS
//



//
//RETURNS
//

//This simple return just hosts relevant info to the takeoff, as well as the affiliated picture. Nothing fancy to see here.

 if (takeoffInfo) {
  return(
    <div className="card-wrapper medium-text">
      <div ref={cardRef} className="card-info-wrapper image">
        <img className="card-image" src={takeoffInfo[7]}></img>
      </div>
      <hr style={{width: '90%'}}></hr>
      <div className="card-info-wrapper">
        <div className="card-info">Unit Count: {takeoffInfo[4]}</div>
        <div className="card-info">Company: {takeoffInfo[0]}</div>
        <div className="card-info">Name: {takeoffInfo[1]}</div>
        <div className="card-info">Address: {takeoffInfo[2]}</div>
        <div className="card-info">City,State: {takeoffInfo[3]}</div>
        <div className="card-info">Submittal is: {takeoffInfo[5]}</div>
        <a target="_blank" href={takeoffInfo[6]}>{takeoffInfo[6]}</a>
      </div>
      
  </div>
  )
 }
  

}
export default TakeoffCard