import { useState, useEffect, useRef } from "react";
import AIDSelect from "../MainComponents/AIDSelect";

const DocsData = ({jobsData,baseUrl,setOverlay,docData, refreshDocs}) => {
//
//HOOKS
//

//newStringDate simply holds the date that changes from YYYY-MM-DD to MM/DD/YYYY
const [newStringDate, setNewStringDate] = useState('')

//editState is what re-renders a document card when the user double clicks to edit, or saves the edit. It can hold either true or false.
const [editState, setEditState] = useState(false)

//This reference encapsulates the AIDSelect select element.
const chosenJobRef = useRef()

//These 5 references encapsulate the 5 different document types & their respective form elements below.
//These are used to quickly pull form data, when the user edits a document.
const billFormRef = useRef()
const orderFormRef = useRef()
const quoteFormRef = useRef()
const COFormRef = useRef()
const PCOFormRef = useRef()
const quickQuoteFormRef = useRef()
//
//EFFECTS
//

//This useEffect simply re-formats the date, as explained above
useEffect(() => {
  let newDateFormat = docData[3]
  let arrSplit = newDateFormat.split('-')
  let newStringDate = arrSplit[1] + '/' + arrSplit[2] + '/' + arrSplit[0] 
  setNewStringDate(newStringDate)
}, [])
//
//FUNCS
//

//I will go into great detail explaining processForm.
//At the top level we establish a new FormData object (parcelFormData), which isn't necessary, but it's how I pass everything to the back-end.
//Then there is a switch, that takes the 5 different bill types. Considering that all 5 bill types are saved differently, this is necessary.
//Inside of the switches, I append key value pairs to parcelFormData, they K/V pairs are then send to the server & saved.
//After that, the overlay is set to the response (success), but if the fetch catches an error, it will send "failure", and the error message to the user through the overlay.
//The names & form objects of the five docs below are different, but the methods of saving them are exactly the same.
const processForm = async(val) => {
  let parcelFormData = new FormData()
  switch (val) {
    case 'bill': {
      parcelFormData.append('DocID', docData[1])
      parcelFormData.append('AID', billFormRef.current[0].value)
      parcelFormData.append('date', billFormRef.current[1].value)
      parcelFormData.append('total', billFormRef.current[2].value)
      parcelFormData.append('collected', billFormRef.current[3].value)
      parcelFormData.append('type', billFormRef.current[4].value)
      parcelFormData.append('signature', billFormRef.current[5].value)
      parcelFormData.append('billName', billFormRef.current[6].value)
      parcelFormData.append('billAddress', billFormRef.current[7].value)
      parcelFormData.append('billCityState', billFormRef.current[8].value)
      parcelFormData.append('shipName', billFormRef.current[9].value)
      parcelFormData.append('shipAddress', billFormRef.current[10].value)
      parcelFormData.append('shipCityState', billFormRef.current[11].value)
      parcelFormData.append('description', billFormRef.current[12].value)

      const res = await fetch(baseUrl + 'editing/bill', {
        method: 'POST',
        body: parcelFormData
      })
      .then((res) => {
        if (res.ok) {return res.json()} throw new Error('Bad Request')
      })
      .then(data => {
        console.log(data)
        setOverlay(data.response)
        refreshDocs()
      })
      .catch((error) => [
        setOverlay('failure', error)
      ])

    }break;
    case 'quote': {
      parcelFormData.append('DocID', docData[1])
      parcelFormData.append('AID', quoteFormRef.current[0].value)
      parcelFormData.append('date', quoteFormRef.current[1].value)
      parcelFormData.append('total', quoteFormRef.current[2].value)
      parcelFormData.append('collected', 0)
      parcelFormData.append('type', quoteFormRef.current[3].value)
      parcelFormData.append('signature', quoteFormRef.current[4].value)
      parcelFormData.append('architect', quoteFormRef.current[5].value)
      parcelFormData.append('plansDate', quoteFormRef.current[6].value)
      parcelFormData.append('bidCode', quoteFormRef.current[7].value)
      parcelFormData.append('contactName', quoteFormRef.current[8].value)
      parcelFormData.append('contactCompany', quoteFormRef.current[9].value)
      parcelFormData.append('contactEmail', quoteFormRef.current[10].value)
      parcelFormData.append('shipName', quoteFormRef.current[11].value)
      parcelFormData.append('shipAddress', quoteFormRef.current[12].value)
      parcelFormData.append('shipCityState', quoteFormRef.current[13].value)
      parcelFormData.append('description', quoteFormRef.current[14].value)

      const res = await fetch(baseUrl + 'editing/quote', {
        method: 'POST',
        body: parcelFormData
      })
      .then((res) => {
        if (res.ok) {return res.json()} throw new Error('Bad Request')
      })
      .then(data => {
        console.log(data)
        setOverlay(data.response)
        refreshDocs()
      })
      .catch((error) => [
        setOverlay('failure', error)
      ])
    }
    case 'quickQuote':{
      // console.log(quickQuoteFormRef.current[0].value)
      // console.log(docData[1])
      // console.log(quickQuoteFormRef.current[0].value)
      // console.log(quickQuoteFormRef.current[1].value)
      // console.log(quickQuoteFormRef.current[2].value)
      // console.log(quickQuoteFormRef.current[3].value)
      // console.log(quickQuoteFormRef.current[4].value)
      // console.log(quickQuoteFormRef.current[5].value)
      // console.log(quickQuoteFormRef.current[6].value)
      // console.log(quickQuoteFormRef.current[8].value)
      // console.log(quickQuoteFormRef.current[9].value)
      // console.log(quickQuoteFormRef.current[11].value)

      parcelFormData.append('DocID', docData[1])
      parcelFormData.append('AID', quickQuoteFormRef.current[0].value)
      parcelFormData.append('date', quickQuoteFormRef.current[1].value)
      parcelFormData.append('total', quickQuoteFormRef.current[2].value)
      parcelFormData.append('collected', 0)
      parcelFormData.append('type', quickQuoteFormRef.current[3].value)
      parcelFormData.append('signature', quickQuoteFormRef.current[4].value)
      parcelFormData.append('architect', quickQuoteFormRef.current[5].value)
      parcelFormData.append('plansDate', quickQuoteFormRef.current[6].value)
      parcelFormData.append('contactName', quickQuoteFormRef.current[7].value)
      parcelFormData.append('contactCompany', quickQuoteFormRef.current[8].value)
      parcelFormData.append('shipNameAddress', quickQuoteFormRef.current[9].value)
      parcelFormData.append('shipCityState', quickQuoteFormRef.current[10].value)
      parcelFormData.append('description', quickQuoteFormRef.current[11].value)
      const res = await fetch(baseUrl + 'editing/quickQuote', {
        method: 'POST',
        body: parcelFormData
      })
      .then((res) => {
        if (res.ok) {return res.json()} throw new Error('Bad Request')
      })
      .then(data => {
        console.log(data)
        setOverlay(data.response)
        refreshDocs()
      })
      .catch((error) => [
        setOverlay('failure', error)
      ])
    }
    break;
    case 'order': {
      parcelFormData.append('DocID', docData[1])
      parcelFormData.append('AID', orderFormRef.current[0].value)
      parcelFormData.append('date', orderFormRef.current[1].value)
      parcelFormData.append('total', orderFormRef.current[2].value)
      parcelFormData.append('collected', 0)
      parcelFormData.append('type', orderFormRef.current[3].value)
      parcelFormData.append('signature', orderFormRef.current[4].value)
      parcelFormData.append('vendorName', orderFormRef.current[5].value)
      parcelFormData.append('vendorAddress', orderFormRef.current[6].value)
      parcelFormData.append('vendorCityState', orderFormRef.current[7].value)
      parcelFormData.append('shipName', orderFormRef.current[8].value)
      parcelFormData.append('shipAddress', orderFormRef.current[9].value)
      parcelFormData.append('shipCityState', orderFormRef.current[10].value)
      parcelFormData.append('description', orderFormRef.current[11].value)

      const res = await fetch(baseUrl + 'editing/order', {
        method: 'POST',
        body: parcelFormData
      })
      .then((res) => {
        if (res.ok) {return res.json()} throw new Error('Bad Request')
      })
      .then(data => {
        console.log(data)
        setOverlay(data.response)
      })
      .catch((error) => [
        setOverlay('failure', error)
      ])
    }break;
    case 'co': {
      parcelFormData.append('DocID', docData[1])
      parcelFormData.append('AID', COFormRef.current[0].value)
      parcelFormData.append('date', COFormRef.current[1].value)
      parcelFormData.append('total', COFormRef.current[2].value)
      parcelFormData.append('collected', COFormRef.current[3].value)
      parcelFormData.append('type', COFormRef.current[4].value)
      parcelFormData.append('signature', COFormRef.current[5].value)
      parcelFormData.append('contactName', COFormRef.current[6].value)
      parcelFormData.append('contactCompany', COFormRef.current[7].value)
      parcelFormData.append('contactEmail', COFormRef.current[8].value)
      parcelFormData.append('billName', COFormRef.current[9].value)
      parcelFormData.append('billAddress', COFormRef.current[10].value)
      parcelFormData.append('billCityState', COFormRef.current[11].value)
      parcelFormData.append('shipName', COFormRef.current[12].value)
      parcelFormData.append('shipAddress', COFormRef.current[13].value)
      parcelFormData.append('shipCityState', COFormRef.current[14].value)
      parcelFormData.append('description', COFormRef.current[15].value)

      const res = await fetch(baseUrl + 'editing/CO', {
        method: 'POST',
        body: parcelFormData
      })
      .then((res) => {
        if (res.ok) {return res.json()} throw new Error('Bad Request')
      })
      .then(data => {
        console.log(data)
        setOverlay(data.response)
      })
      .catch((error) => [
        setOverlay('failure', error)
      ])
    }break;
    case 'pco': {
      parcelFormData.append('DocID', docData[1])
      parcelFormData.append('AID', PCOFormRef.current[0].value)
      parcelFormData.append('date', PCOFormRef.current[1].value)
      parcelFormData.append('total', PCOFormRef.current[2].value)
      parcelFormData.append('collected', 0)
      parcelFormData.append('type', PCOFormRef.current[3].value)
      parcelFormData.append('signature', PCOFormRef.current[4].value)
      parcelFormData.append('contactName', PCOFormRef.current[5].value)
      parcelFormData.append('contactCompany', PCOFormRef.current[6].value)
      parcelFormData.append('contactEmail', PCOFormRef.current[7].value)
      parcelFormData.append('shipName', PCOFormRef.current[8].value)
      parcelFormData.append('shipAddress', PCOFormRef.current[9].value)
      parcelFormData.append('shipCityState', PCOFormRef.current[10].value)
      parcelFormData.append('description', PCOFormRef.current[11].value)

      const res = await fetch(baseUrl + 'editing/PCO', {
        method: 'POST',
        body: parcelFormData
      })
      .then((res) => {
        if (res.ok) {return res.json()} throw new Error('Bad Request')
      })
      .then(data => {
        console.log(data)
        setOverlay(data.response)
      })
      .catch((error) => [
        setOverlay('failure', error)
      ])
    }break;
    case 'workOrder': {
      parcelFormData.append('DocID', docData[1])
      parcelFormData.append('AID', PCOFormRef.current[0].value)
      parcelFormData.append('date', PCOFormRef.current[1].value)
      parcelFormData.append('total', PCOFormRef.current[2].value)
      parcelFormData.append('collected', 0)
      parcelFormData.append('type', PCOFormRef.current[3].value)
      parcelFormData.append('signature', PCOFormRef.current[4].value)
      parcelFormData.append('contractorName', PCOFormRef.current[5].value)
      parcelFormData.append('contractorCompany', PCOFormRef.current[6].value)
      parcelFormData.append('contractorEmail', PCOFormRef.current[7].value)
      parcelFormData.append('jobsiteName', PCOFormRef.current[8].value)
      parcelFormData.append('jobsiteAddress', PCOFormRef.current[9].value)
      parcelFormData.append('jobsiteCityState', PCOFormRef.current[10].value)
      parcelFormData.append('description', PCOFormRef.current[11].value)

      const res = await fetch(baseUrl + 'editing/workOrder', {
        method: 'POST',
        body: parcelFormData
      })
      .then((res) => {
        if (res.ok) {return res.json()} throw new Error('Bad Request')
      })
      .then(data => {
        console.log(data)
        setOverlay(data.response)
      })
      .catch((error) => [
        setOverlay('failure', error)
      ])
    }break;
  }
}
//
//RETURNS
//

//There are 5 different types of returns, each with two varients (default & edit mode).
//These 5 returns are deliniated over a long if condition(could have also been a switch), to determine which to show.
//I did this instead of writing 5 different functional components, considering they're almost all the same.
//I won't go greatly into detail into these returns, but if you ever need to edit them, be sure to console.log(docData) to determine the indexes.


if (docData[0] == 'docBill') {
  if (editState == false) {
    if (docData[4] == docData[5]) {
      return(<div className='card-wrapper docs'>
      <div className="card-info-wrapper row green" >
        <div className="card-info-column" >
          <div className="card-info">{docData[1]}</div>
        </div>
        <div className="card-info-column">
          <div className="card-info">BILL</div>
        </div>
        
      </div>
      <div className="card-info-wrapper docs green">
      <div className="card-info-row">
        <div className='medium-text'>AMP ID: </div>
        <div className="medium-text">{docData[2]}</div>
      </div>
      <div className="card-info-row">
        <div className='medium-text'>Date: </div>
        <div className="medium-text">{newStringDate}</div>
      </div>
      <div className="card-info-row">
        <div className='medium-text'>Total $: </div>
        <div className="medium-text">${docData[4]}</div>
      </div>
      <div className="card-info-row">
        <div className='medium-text'>Collected $: </div>
        <div className="medium-text">${docData[5]}</div>
      </div>
      <div className="card-info-row">
        <div className='medium-text'>Cost Type: </div>
        <div className="medium-text">{docData[6]}</div>
      </div>
      <div className="card-info-row">
        <div className='medium-text'>Signature: </div>
        <div className="medium-text">{docData[7]}</div>
      </div>
      <div className="card-info-row">
        <div className='medium-text'>Bill To: </div>
        <div className="medium-text">{docData[8]}</div>
      </div>
      <div className="card-info-row">
        <div className='medium-text'>Bill Address: </div>
        <div className="medium-text">{docData[9]}</div>
      </div>
      <div className="card-info-row">
        <div className='medium-text'>Bill City,State: </div>
        <div className="medium-text">{docData[10]}</div>
      </div>
      <div className="card-info-row">
        <div className='medium-text'>Ship To: </div>
        <div className="medium-text">{docData[11]}</div>
      </div>
      <div className="card-info-row">
        <div className='medium-text'>ShipAddress: </div>
        <div className="medium-text">{docData[12]}</div>
      </div>
      <div className="card-info-row">
        <div className='medium-text'>Ship City,State: </div>
        <div className="medium-text">{docData[13]}</div>
      </div>
      
      <div className="card-info-row">
        <div className='medium-text'>Description: </div>
        <div className="medium-text">{docData[17]}</div>
      </div>
  </div>
  <div onDoubleClick={() => {setEditState(true);}} className="input-field button jobs">DOUBLE CLICK TO EDIT</div>
    </div>)
    } else {
    return(
      <div className='card-wrapper docs'>
        <div className="card-info-wrapper row red" >
          <div className="card-info-column" >
            <div className="card-info">{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info">BILL</div>
          </div>
          
        </div>
        <div className="card-info-wrapper docs red">
        <div className="card-info-row">
          <div className='medium-text'>AMP ID: </div>
          <div className="medium-text">{docData[2]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Date: </div>
          <div className="medium-text">{newStringDate}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Total $: </div>
          <div className="medium-text">${docData[4]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Collected $: </div>
          <div className="medium-text">${docData[5]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Cost Type: </div>
          <div className="medium-text">{docData[6]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Signature: </div>
          <div className="medium-text">{docData[7]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Bill To: </div>
          <div className="medium-text">{docData[8]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Bill Address: </div>
          <div className="medium-text">{docData[9]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Bill City,State: </div>
          <div className="medium-text">{docData[10]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship To: </div>
          <div className="medium-text">{docData[11]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>ShipAddress: </div>
          <div className="medium-text">{docData[12]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship City,State: </div>
          <div className="medium-text">{docData[13]}</div>
        </div>
        
        <div className="card-info-row">
          <div className='medium-text'>Description: </div>
          <div className="medium-text">{docData[17]}</div>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(true);}} className="input-field button jobs">DOUBLE CLICK TO EDIT</div>
      </div>
    )
    }
  } else if (editState == true) {
    return(
      <form ref={billFormRef} className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info"  >{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info"  >BILL</div>
          </div>
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='smallish-text'>AMP ID: </div>
          <AIDSelect defaultAID={docData[2]} chosenJobRef={chosenJobRef} jobsData={jobsData} style={{height: '100%', marginTop: '-6px'}} />
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Date: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[3]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Total $: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[4]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Collected $: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[5]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Cost Type: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[6]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Signature: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[7]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Bill To: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[8]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Bill Address: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[9]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Bill City,State: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[10]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship To: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[11]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship Address: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[12]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship City,State: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[13]}></input>
        </div>
        
        <div className="card-info-row">
          <div className='smallish-text'>Description: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[17]}></input>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(false);processForm('bill')}} className="input-field button jobs green">DOUBLE CLICK TO SUBMIT</div>
      </form>
    )
  }
  
}  else if (docData[0] == 'docOrder') {
  if (editState == false) {
    return(
      <div className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info">{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info">ORDER</div>
          </div>
          
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='medium-text'>AMP ID: </div>
          <div className="medium-text">{docData[2]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Date: </div>
          <div className="medium-text">{newStringDate}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Total $: </div>
          <div className="medium-text">${docData[4]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Cost Type: </div>
          <div className="medium-text">{docData[6]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Signature: </div>
          <div className="medium-text">{docData[7]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Vendor Name: </div>
          <div className="medium-text">{docData[8]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Vendor Address: </div>
          <div className="medium-text">{docData[9]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Vendor City,State: </div>
          <div className="medium-text">{docData[10]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship To: </div>
          <div className="medium-text">{docData[11]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship Address: </div>
          <div className="medium-text">{docData[12]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship City,State: </div>
          <div className="medium-text">{docData[13]}</div>
        </div>
        
        <div className="card-info-row">
          <div className='medium-text'>Description: </div>
          <div className="medium-text">{docData[17]}</div>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(true)}} className="input-field button jobs">DOUBLE CLICK TO EDIT</div>
      </div>
    )
  } else if (editState == true) {
    return(
      <form ref={orderFormRef} className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info"  >{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info"  >ORDER</div>
          </div>
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='smallish-text'>AMP ID: </div>
          <AIDSelect defaultAID={docData[2]} chosenJobRef={chosenJobRef} jobsData={jobsData} style={{height: '100%', marginTop: '-6px'}} />
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Date: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[3]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Total $: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[4]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Cost Type: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[6]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Signature: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[7]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Vendor Name: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[8]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Vendor Address: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[9]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Vendor City,State: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[10]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship To: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[11]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>ShipAddress: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[12]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship City,State: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[13]}></input>
        </div>
        
        <div className="card-info-row">
          <div className='smallish-text'>Description: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[17]}></input>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(false);processForm('order')}} className="input-field button jobs green">DOUBLE CLICK TO SUBMIT</div>
  </form>
    )
  }
}  else if (docData[0] == 'docQuote') {
  if (editState == false) {
    return(
      <div className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info">{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info">QUOTE</div>
          </div>
          
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='medium-text'>AMP ID: </div>
          <div className="medium-text">{docData[2]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Date: </div>
          <div className="medium-text">{newStringDate}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Total $: </div>
          <div className="medium-text">${docData[4]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Cost Type: </div>
          <div className="medium-text">{docData[6]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Signature: </div>
          <div className="medium-text">{docData[7]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Architect: </div>
          <div className="medium-text">{docData[14]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Plans Date: </div>
          <div className="medium-text">{docData[15]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Bid Code: </div>
          <div className="medium-text">{docData[16]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contact Name: </div>
          <div className="medium-text">{docData[8]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contact Company: </div>
          <div className="medium-text">{docData[9]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contact Email: </div>
          <div className="medium-text">{docData[10]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship To: </div>
          <div className="medium-text">{docData[11]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship Address: </div>
          <div className="medium-text">{docData[12]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship City,State: </div>
          <div className="medium-text">{docData[13]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Description: </div>
          <div className="medium-text">{docData[17]}</div>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(true)}} className="input-field button jobs">DOUBLE CLICK TO EDIT</div>
      </div>
    )
  } else if (editState == true) {
    return(
      <form ref={quoteFormRef} className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info"  >{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info"  >QUOTE</div>
          </div>
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='smallish-text'>AMP ID: </div>
          <AIDSelect defaultAID={docData[2]} chosenJobRef={chosenJobRef} jobsData={jobsData} style={{height: '100%', marginTop: '-6px'}} />
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Date: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[3]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Total $: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[4]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Cost Type: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[6]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Signature: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[7]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Architect: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[14]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Plans Date: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[15]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Bid Code: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[16]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Contact Name: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[8]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Contact Company: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[9]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Contact Email: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[10]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship To: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[11]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>ShipAddress: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[12]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship City,State: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[13]}></input>
        </div>
        
        <div className="card-info-row">
          <div className='smallish-text'>Description: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[17]}></input>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(false);processForm('quote')}} className="input-field button jobs green">DOUBLE CLICK TO SUBMIT</div>
  </form>
    )
  }
} else if (docData[0] == 'quickQuote') {
  if (editState == false) {
    return(
      <div className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info">{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info">QWIK QUOTE</div>
          </div>
          
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='medium-text'>AMP ID: </div>
          <div className="medium-text">{docData[2]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Date: </div>
          <div className="medium-text">{newStringDate}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Total $: </div>
          <div className="medium-text">${docData[4]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Cost Type: </div>
          <div className="medium-text">{docData[6]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Signature: </div>
          <div className="medium-text">{docData[7]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Architect: </div>
          <div className="medium-text">{docData[14]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Plans Date: </div>
          <div className="medium-text">{docData[15]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contact Name: </div>
          <div className="medium-text">{docData[8]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contact Company: </div>
          <div className="medium-text">{docData[9]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship Name / Address: </div>
          <div className="medium-text">{docData[11]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship City,State: </div>
          <div className="medium-text">{docData[12]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Description: </div>
          <div className="medium-text">{docData[17]}</div>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(true)}} className="input-field button jobs">DOUBLE CLICK TO EDIT</div>
      </div>
    )
  } else if (editState == true) {
    return(
      <form ref={quickQuoteFormRef} className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info"  >{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info"  >QwWIK</div>
          </div>
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='smallish-text'>AMP ID: </div>
          <AIDSelect defaultAID={docData[2]} chosenJobRef={chosenJobRef} jobsData={jobsData} style={{height: '100%', marginTop: '-6px'}} />
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Date: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[3]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Total $: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[4]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Cost Type: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[6]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Signature: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[7]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Architect: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[14]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Plans Date: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[15]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Contact Name: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[8]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Contact Company: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[9]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship Name / Address: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[11]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship City,State: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[12]}></input>
        </div>
        
        <div className="card-info-row">
          <div className='smallish-text'>Description: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[17]}></input>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(false);processForm('quickQuote')}} className="input-field button jobs green">DOUBLE CLICK TO SUBMIT</div>
  </form>
    )
  }
} else if (docData[0] == 'docCO') {
  if (editState == false) {
    if (docData[4] == docData[5]) {
      return(
        <div className='card-wrapper docs'>
          <div className="card-info-wrapper row" >
            <div className="card-info-column" >
              <div className="card-info">{docData[1]}</div>
            </div>
            <div className="card-info-column">
              <div className="card-info">CHANGE ORDER</div>
            </div>
            
          </div>
          <div className="card-info-wrapper docs green">
          <div className="card-info-row">
            <div className='medium-text'>AMP ID: </div>
            <div className="medium-text">{docData[2]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Date: </div>
            <div className="medium-text">{newStringDate}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Total $: </div>
            <div className="medium-text">${docData[4]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Collected $: </div>
            <div className="medium-text">${docData[5]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Cost Type: </div>
            <div className="medium-text">{docData[6]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Signature: </div>
            <div className="medium-text">{docData[7]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Contact Name: </div>
            <div className="medium-text">{docData[8]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Contact Company: </div>
            <div className="medium-text">{docData[9]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Contact Email: </div>
            <div className="medium-text">{docData[10]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Bill To: </div>
            <div className="medium-text">{docData[11]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Bill Address: </div>
            <div className="medium-text">{docData[12]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Bill City,State: </div>
            <div className="medium-text">{docData[13]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Ship To: </div>
            <div className="medium-text">{docData[14]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Ship Address: </div>
            <div className="medium-text">{docData[15]}</div>
          </div>
          <div className="card-info-row">
            <div className='medium-text'>Ship City,State: </div>
            <div className="medium-text">{docData[16]}</div>
          </div>
          
          <div className="card-info-row">
            <div className='medium-text'>Description: </div>
            <div className="medium-text">{docData[17]}</div>
          </div>
      </div>
      <div onDoubleClick={() => {setEditState(true)}} className="input-field button jobs">DOUBLE CLICK TO EDIT</div>
        </div>
      )
    } else {
    return(
      <div className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info">{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info">CHANGE ORDER</div>
          </div>
          
        </div>
        <div className="card-info-wrapper docs red">
        <div className="card-info-row">
          <div className='medium-text'>AMP ID: </div>
          <div className="medium-text">{docData[2]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Date: </div>
          <div className="medium-text">{newStringDate}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Total $: </div>
          <div className="medium-text">${docData[4]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Collected $: </div>
          <div className="medium-text">${docData[5]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Cost Type: </div>
          <div className="medium-text">{docData[6]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Signature: </div>
          <div className="medium-text">{docData[7]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contact Name: </div>
          <div className="medium-text">{docData[8]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contact Company: </div>
          <div className="medium-text">{docData[9]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contact Email: </div>
          <div className="medium-text">{docData[10]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Bill To: </div>
          <div className="medium-text">{docData[11]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Bill Address: </div>
          <div className="medium-text">{docData[12]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Bill City,State: </div>
          <div className="medium-text">{docData[13]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship To: </div>
          <div className="medium-text">{docData[14]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship Address: </div>
          <div className="medium-text">{docData[15]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship City,State: </div>
          <div className="medium-text">{docData[16]}</div>
        </div>
        
        <div className="card-info-row">
          <div className='medium-text'>Description: </div>
          <div className="medium-text">{docData[17]}</div>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(true)}} className="input-field button jobs">DOUBLE CLICK TO EDIT</div>
      </div>
    )
   }
  } else if (editState == true) {
    return(
      <form ref={COFormRef} className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info"  >{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info"  >CHANGE ORDER</div>
          </div>
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='smallish-text'>AMP ID: </div>
          <AIDSelect defaultAID={docData[2]} chosenJobRef={chosenJobRef} jobsData={jobsData} style={{height: '100%', marginTop: '-6px'}} />
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Date: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[3]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Total $: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[4]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Collected $: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[5]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Cost Type: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[6]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Signature: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[7]} ></input>
        </div>
        
        <div className="card-info-row">
          <div className='smallish-text'>Contact Name: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[8]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Contact Company: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[9]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Contact Email: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[10]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Bill To: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[11]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Bill Address: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[12]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Bill City,State: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[13]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship To: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[14]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>ShipAddress: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[15]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship City,State: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[16]}></input>
        </div>
        
        <div className="card-info-row">
          <div className='smallish-text'>Description: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[17]}></input>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(false);processForm('co')}} className="input-field button jobs green">DOUBLE CLICK TO SUBMIT</div>
  </form>
    )
  }
} else if (docData[0] == 'docPCO') {
  if (editState == false) {
    return(
      <div className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info">{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info">P. CHANGE ORDER</div>
          </div>
          
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='medium-text'>AMP ID: </div>
          <div className="medium-text">{docData[2]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Date: </div>
          <div className="medium-text">{newStringDate}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Total $: </div>
          <div className="medium-text">${docData[4]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Cost Type: </div>
          <div className="medium-text">{docData[6]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Signature: </div>
          <div className="medium-text">{docData[7]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contact Name: </div>
          <div className="medium-text">{docData[8]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contact Company: </div>
          <div className="medium-text">{docData[9]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contact Email: </div>
          <div className="medium-text">{docData[10]}</div>
        </div>

        <div className="card-info-row">
          <div className='medium-text'>Ship To: </div>
          <div className="medium-text">{docData[11]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship Address: </div>
          <div className="medium-text">{docData[12]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Ship City,State: </div>
          <div className="medium-text">{docData[13]}</div>
        </div>
        
        <div className="card-info-row">
          <div className='medium-text'>Description: </div>
          <div className="medium-text">{docData[17]}</div>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(true)}} className="input-field button jobs">DOUBLE CLICK TO EDIT</div>
      </div>
    )
  } else if (editState == true) {
    return(
      <form ref={PCOFormRef} className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info"  >{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info"  >P. CHANGE ORDER</div>
          </div>
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='smallish-text'>AMP ID: </div>
          <AIDSelect defaultAID={docData[2]} chosenJobRef={chosenJobRef} jobsData={jobsData} style={{height: '100%', marginTop: '-6px'}} />
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Date: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[3]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Total $: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[4]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Cost Type: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[6]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Signature: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[7]} ></input>
        </div>
        
        <div className="card-info-row">
          <div className='smallish-text'>Contact Name: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[8]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Contact Company: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[9]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Contact Email: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[10]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship To: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[11]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>ShipAddress: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[12]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Ship City,State: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[13]}></input>
        </div>
        
        <div className="card-info-row">
          <div className='smallish-text'>Description: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[17]}></input>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(false);processForm('pco')}} className="input-field button jobs green">DOUBLE CLICK TO SUBMIT</div>
  </form>
    )
  }
}
else if (docData[0] == 'workOrder') {
  if (editState == false) {
    return(
      <div className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info">{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info">WORK ORDER</div>
          </div>
          
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='medium-text'>AMP ID: </div>
          <div className="medium-text">{docData[2]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Date: </div>
          <div className="medium-text">{newStringDate}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Total $: </div>
          <div className="medium-text">${docData[4]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Cost Type: </div>
          <div className="medium-text">{docData[6]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Signature: </div>
          <div className="medium-text">{docData[7]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contractor Name: </div>
          <div className="medium-text">{docData[8]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contractor Company: </div>
          <div className="medium-text">{docData[9]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Contractor Email: </div>
          <div className="medium-text">{docData[10]}</div>
        </div>

        <div className="card-info-row">
          <div className='medium-text'>Jobsite Name: </div>
          <div className="medium-text">{docData[11]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Jobsite Address: </div>
          <div className="medium-text">{docData[12]}</div>
        </div>
        <div className="card-info-row">
          <div className='medium-text'>Jobsite City,State: </div>
          <div className="medium-text">{docData[13]}</div>
        </div>
        
        <div className="card-info-row">
          <div className='medium-text'>Description: </div>
          <div className="medium-text">{docData[17]}</div>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(true)}} className="input-field button jobs">DOUBLE CLICK TO EDIT</div>
      </div>
    )
  } else if (editState == true) {
    return(
      <form ref={PCOFormRef} className='card-wrapper docs'>
        <div className="card-info-wrapper row" >
          <div className="card-info-column" >
            <div className="card-info"  >{docData[1]}</div>
          </div>
          <div className="card-info-column">
            <div className="card-info"  >P. CHANGE ORDER</div>
          </div>
        </div>
        <div className="card-info-wrapper docs">
        <div className="card-info-row">
          <div className='smallish-text'>AMP ID: </div>
          <AIDSelect defaultAID={docData[2]} chosenJobRef={chosenJobRef} jobsData={jobsData} style={{height: '100%', marginTop: '-6px'}} />
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Date: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[3]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Total $: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[4]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Cost Type: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[6]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Signature: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[7]} ></input>
        </div>
        
        <div className="card-info-row">
          <div className='smallish-text'>Contractor Name: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[8]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Contractor Company: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[9]} ></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Contractor Email: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[10]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Jobsite Name: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[11]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Jobsite Address: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[12]}></input>
        </div>
        <div className="card-info-row">
          <div className='smallish-text'>Jobsite City,State: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[13]}></input>
        </div>
        
        <div className="card-info-row">
          <div className='smallish-text'>Description: </div>
          <input className="input-field job" style={{height: '100%', marginTop: '-6px'}} defaultValue={docData[17]}></input>
        </div>
    </div>
    <div onDoubleClick={() => {setEditState(false);processForm('workOrder')}} className="input-field button jobs green">DOUBLE CLICK TO SUBMIT</div>
  </form>
    )
  }
} else {
  return
}

}
export default DocsData