import {useState, useEffect} from 'react'
import {BiCheckDouble,BiCloudUpload} from 'react-icons/bi'

const FileDragDrop = ({setFile}) => {
  //
//HOOKS
//
const [screenType, setScreenType] = useState()
const [updloadStatus, setUploadStatus] = useState(false)
const [fileName, setFileName] = useState('')
//
//EFFECTS
//
useEffect(() => {
  const resolution = window.innerWidth;
  if (resolution >= 320 && resolution <= 480) {
      setScreenType('mobile')
  } else if (resolution >= 768 && resolution <= 1024) {
      setScreenType('tablet')
  } else {
      setScreenType('desktop')
  }
},[])
//
//FUNCS
//
const dragOver = (e) => {
  e.preventDefault();
}
const dragEnter = (e) => {
  e.preventDefault();
}
const dragLeave = (e) => {
  e.preventDefault();
}
const fileDrop = (e) => {
  e.preventDefault()
  const files = e.dataTransfer.files;
  setFile(files);
  setUploadStatus(true)
}
const fileUpload = (e) => {
  e.preventDefault();
  const files = e.target.files
  // console.log(e.target.files)
  setFileName(e.target.files[0].name)
  setFile(files)
  setUploadStatus(true)

}
//
//RETURNS
//
const DeviceSplitterDropZone = () => {
  if (screenType == 'desktop') {
    return(
      <div className='drop-wrapper'>
        <div className='drop-container'
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        >
          <MessageDesktop />
          <input name="fileField" onChange={fileUpload} className='upload-button' type='file'></input>
        </div>
      </div>
    )
  } else if (screenType == 'mobile' || screenType == 'tablet') {
    return(
      <div className='drop-container mobile'>
          <MessageMobile />
          <input name="fileField" onChange={fileUpload} className='upload-button' type='file'></input>
      </div>
    )
  } else {
    return(<div>This is not supported on your device!</div>)
  }
}

const MessageDesktop = () => {
  if (updloadStatus == false) {
    return(
      <div className='drop-message noFile'>
        <div className='drop-icon'><BiCloudUpload/></div>
      </div>
    )
  } else if (updloadStatus == true) {
    return(
      <div className='drop-message'>{fileName}
        <div className='drop-icon'><BiCheckDouble /></div>
      </div>
    )
  } else {return(<div>ERROR uploadStatus has neither true or false values. FILEDRAGDROP.JS</div>)}
}
const MessageMobile = () => {
  if (updloadStatus == false) {
    return(
      <div className='drop-message noFile mobile'>
        <div className='drop-icon mobile'><BiCloudUpload/></div>
      </div>
    )
  } else if (updloadStatus == true) {
    return(
      <div className='drop-message mobile'>{fileName}
        <div className='drop-icon mobile'><BiCheckDouble /></div>
      </div>
    )
  } else {return(<div>ERROR uploadStatus has neither true or false values. FILEDRAGDROP.JS</div>)}
}

return(<DeviceSplitterDropZone />)
}

export default FileDragDrop