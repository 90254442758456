import { useState, useEffect, useRef } from "react";
import FileDragDrop from "./FileDragDrop";
import AIDSelect from './AIDSelect'
import InvoicingCompany from './InvoicingCompany'

const AutoFile = ({jobsData,baseUrl,setOverlay,vendorData}) => {
//
//HOOKS
//

//autoFileData encapsulates all of the recognized values from the Mindee Bot (API I use for auto filing)
const [autoFileData, setAutoFileData] = useState()

//isThereAFile is a simple boolean state that is used to re-render the UI to show user the file has been uploaded.
const [isThereAFile, setIsThereAFile] = useState(false)

//fileRef references the actual data of the file. This is used to send to Mindee
const fileRef = useRef()

//chosenJobRef is explained elsewhere lol
const chosenJobRef = useRef()

//invoiceFormRef encapsulates the form element, and all of the input children.
const invoiceFormRef = useRef()
//
//EFFECTS
//

//
//FUNCS
//

//setFile runs when a file is added to the input field.
//the timeouts are used to ensure the file is fully uploaded before sending it off to Mindee.
const setFile = async(file) => {
  fileRef.current = file
  setTimeout(setIsThereAFile(true), 7000)
  setTimeout(submitFile(), 1000)
  // console.log(fileRef.current)
}

//submitFile is what sends the fileRef (reference that encapsulates the file in the input field) to Mindee.
//upon response, the JSON data Mindee returned is set to autoFileData state.
//when this response state is rendered, the entire invoice form field appears, and the defaultValues of the input fields are matched to what Mindee has returned.
const submitFile = async() => {
  let parcelFile = new FormData()
  parcelFile.append('file', fileRef.current[0])
  parcelFile.append('AID', 'A00')
  console.log(parcelFile.get('file'))
  console.log(parcelFile.get('AID'))
  const res = await fetch(baseUrl + 'invoicing/autoFile', {
    method: 'POST',
    body: parcelFile
  })
  .then(result => result.json())
  .then(data => {
    console.log(data)
    if (Object.keys(data).length) {
      setAutoFileData(data)
    } else {
      setOverlay('failure')
    }
    
  })
}

//submitInvoice is better explained in Invoicing.js. It is almost identical.
//simply it sends the form data to the server back end.
const submitInvoice = async() => {
  if(invoiceFormRef.current[2].value == '' || invoiceFormRef.current[3].value == '' || invoiceFormRef.current[4].value == '' || invoiceFormRef.current[5].value == '' || invoiceFormRef.current[6].value == '' || invoiceFormRef.current[7].value == '')
  {
    window.alert('YOU MUST FILL IN ALL FIELDS!')
  } else {
    if(fileRef.current != null) {
      let parcelFormData = new FormData()
      for (let i = 0; i < fileRef.current.length; i++) {
        parcelFormData.append('file', fileRef.current[i])
      }
      parcelFormData.append('AID',invoiceFormRef.current[0].value)
      parcelFormData.append('company',invoiceFormRef.current[1].value)
      parcelFormData.append('po',invoiceFormRef.current[2].value)
      parcelFormData.append('date',invoiceFormRef.current[3].value)
      parcelFormData.append('type',invoiceFormRef.current[4].value)
      parcelFormData.append('sales',invoiceFormRef.current[5].value)
      parcelFormData.append('total',invoiceFormRef.current[6].value)
      parcelFormData.append('description',invoiceFormRef.current[7].value)
      parcelFormData.append('signature',invoiceFormRef.current[8].value)

      
      // console.log(invoiceFormRef.current[0].value)
      // console.log(invoiceFormRef.current[1].value)
      // console.log(invoiceFormRef.current[2].value)
      // console.log(invoiceFormRef.current[3].value)
      // console.log(invoiceFormRef.current[4].value)
      // console.log(invoiceFormRef.current[5].value)
      // console.log(invoiceFormRef.current[6].value)
      // console.log(invoiceFormRef.current[7].value)
      // console.log(invoiceFormRef.current[8].value)
  
      const res = await fetch(baseUrl + 'invoicing/uploadInvoice', {
        method: 'POST',
        body: parcelFormData
      })
      .then((res) => {
        if (res.ok) {return res.json()} throw new Error('Bad Request')
      })
      .then(data => {
        console.log(data)
        setOverlay(data.response)
      })
      .catch((error) => [
        setOverlay('failure', error)
      ])
  
    } else {
      window.alert('YOU MUST ATTACH A FILE OR PICTURE!')
    }
  }
}
//
//RETURNS
//

//SignatureSelect is hopefully self-explainatory.
const SignatureSelect = () => {
  return(
  <select name="signatureField" className="input-field">
    <option value='seb'>Seb Belfonti</option>
    <option value='allison'>Allison Belfonti</option>
    <option value='mike'>Mike Belfonti</option>
    
  </select>
  )
}

//InvoiceType is hopefully self-explainatory.
const InvoiceType = () => {
  return(
    <select className="input-field" name='typeField'>
      <option value=''>-</option>
      <option value='labor'>Labor</option>
      <option value='material'>Material</option>
      <option value='kitchens'>Kitchens</option>
      <option value='misc'>Misc.</option>
    </select>
  )
}
const SubmitButton = () => {
  return(
  <div name="buttonField" className="input-field button" onClick={submitInvoice}>
    <div className="">SUBMIT</div>
  </div>
  )
}

//Too lazy to dive into the return this morning, sorry Mom.
const PageHandler = () => {
  if (isThereAFile == false) {
    return
  } else if (autoFileData) {

   if (autoFileData.mimeType == "application/pdf") {
    return(
      <form ref={invoiceFormRef}>
        <hr></hr>
        <label className="medium-text" htmlFor='aidSelect'>AMP ID:</label>
        <AIDSelect defaultAID={'A00'} jobsData={jobsData} chosenJobRef={chosenJobRef}/>
        <div className="field-row">
          <div className="field-column">
          <label className="medium-text" htmlFor='companyField'>Invoicing Company:</label>
            <InvoicingCompany vendorData={vendorData} />
            <label className="medium-text" htmlFor='poField'>Invoice PO#:</label>
            <input name="poField" className="input-field" type="text" defaultValue={autoFileData.invoiceNumber.value}></input>
            <label className="medium-text" htmlFor='dateField'>Date on Invoice:</label>
            <input name="dateField" className="input-field" type="date" defaultValue={autoFileData.date.value}></input>
          </div>
          <div className="field-column">
          <label className="medium-text" htmlFor='typeField'>Type of Invoice:</label>
            <InvoiceType />
            <label className="medium-text" htmlFor='taxField'>Sales Tax:</label>
            <input name="taxField" className="input-field" type='number' defaultValue={autoFileData.totalTax.value}></input>
            <label className="medium-text" htmlFor='totalField'>Total (Without Tax):</label>
            <input name="totalField" className="input-field" type="number" defaultValue={autoFileData.totalNet.value}></input>
          </div>
        </div>
    <hr></hr>
    <label className="medium-text" htmlFor='descriptionField'>Brief & Detailed Description:</label>
    <textarea name="descriptionField" className="description-field"></textarea>
          <div className="field-row">
            <div className="field-column">
            <label className="medium-text" htmlFor='signatureField'>Signature:</label>
              <SignatureSelect />
            </div>
            <div className="field-column">
            <label className="medium-text" htmlFor='buttonField'>&nbsp;</label>
              <SubmitButton />
          </div>
        </div>
      </form>
    )
  } else if (autoFileData.mimeType == "image/png" || autoFileData.mimeType == "image/jpg" || autoFileData.mimeType == "image/raw" || autoFileData.mimeType == "image/jpeg") {
    return(
      <form ref={invoiceFormRef}>
        <hr></hr>
        <label className="medium-text" htmlFor='aidSelect'>AMP ID:</label>
        <AIDSelect defaultAID={'A00'} jobsData={jobsData} chosenJobRef={chosenJobRef}/>
        <div className="field-row">
          <div className="field-column">
          <label className="medium-text" htmlFor='companyField'>Invoicing Company:</label>
            <InvoicingCompany vendorData={vendorData} />
            <label className="medium-text" htmlFor='poField'>Invoice PO#:</label>
            <input name="poField" className="input-field" type="text" defaultValue='RECEIPT'></input>
            <label className="medium-text" htmlFor='dateField'>Date on Invoice:</label>
            <input name="dateField" className="input-field" type="date" defaultValue={autoFileData.date.value}></input>
          </div>
          <div className="field-column">
          <label className="medium-text" htmlFor='typeField'>Type of Invoice:</label>
            <InvoiceType />
            <label className="medium-text" htmlFor='taxField'>Sales Tax:</label>
            <input name="taxField" className="input-field" type='number' defaultValue={autoFileData.totalTax.value}></input>
            <label className="medium-text" htmlFor='totalField'>Total (Without Tax):</label>
            <input name="totalField" className="input-field" type="number" defaultValue={autoFileData.totalNet.value}></input>
          </div>
        </div>
    <hr></hr>
    <label className="medium-text" htmlFor='descriptionField'>Brief & Detailed Description:</label>
    <textarea name="descriptionField" className="description-field"></textarea>
          <div className="field-row">
            <div className="field-column">
            <label className="medium-text" htmlFor='signatureField'>Signature:</label>
              <SignatureSelect />
            </div>
            <div className="field-column">
            <label className="medium-text" htmlFor='buttonField'>&nbsp;</label>
              <SubmitButton />
          </div>
        </div>
      </form>
    )
  }
} else {return(<div className="medium-text">One moment while the robots do their work....</div>)}
}
  
return(
  <div className="page-stage medium">
    <label className="medium-text" htmlFor=''>Drag & Drop or Upload Invoice:</label>
    <FileDragDrop setFile={setFile} />
    <PageHandler />
  </div>
)

}
export default AutoFile