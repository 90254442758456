import {useState,useEffect,useRef} from 'react'
import DocsData from './DocsData'
import AIDSelect from '../MainComponents/AIDSelect'
import {BiCheck,BiRecycle,} from 'react-icons/bi'

const DocsBox = ({docsData, jobsData, baseUrl, refreshDocs, setOverlay}) => {
//
//HOOKS
//
  //docsDataBucket is what is rendered, and when filters are applied, this array state changes to match the filter conditions.
  //When this component renders, docsDataBucket takes the initial value of docsData prop.
  const [docsDataBucket, setDocsDataBucket] = useState([])

  //billSelectRef is the reference that is bound to the "doc type" select element.
  const billSelectRef = useRef()

  //jobsRef is the reference that is bound to the AIDSelect element.
  const jobsRef = useRef()
//
//EFFECTS
//

  //As referenced above, once this component mounts, docsDataBucket is assigned the value of the docsData prop.
 useEffect(() => {
  setDocsDataBucket(docsData)
 }, [])
//
//FUNCS
//

 //filterFunc is where the filter magic happens (obviously).. I'll break down how the filtering function works.
 //Firstly docsData is deconstructed into docsDataCopy.
 //Secondly we map over that array, running if conditions to check the status of the select elements, captured by reference.
 //Third we check jobsRef.current.value, if == 'ALL', it won't change the filter. If it has a value, that is checked against the copy.
 //If the chosen AID matches the AID, ( doc[2] ), then that document is pushed to the matchingAIDArray.
 //Fourth we check billSelectRef.current.value, if it equals an empty string, it won't change the filter. If it has a value it is checked against the copy.
 //If the chosen docType matches the document type of the doc we're mapping over ( doc[0] ), then that document is pushed to matchingTypeArray.
 //Fifth, we establish an if condition with 3 outcomes; both filters active, jobs filter active, docType filter active.
 //In the first condition (both filters active), we map over the matchingTypeArray and check against the selected AID. If it matches, that document is pushed to combinedMatchesArray. Then docsDataBucket is assigned combinedMatchesArray.
 //In the second condition (docType aka billSelect is the only one active), we just assign docsDataBucket to matchingTypeArray
 //In the third condition (jobsRef is the only one active), we just assign docsDataBucket to matchingAIDArray
 const filterFunc = () => {
  // console.log(jobsRef.current.value)
  // console.log(billSelectRef.current.value)
  let docsDataCopy = [...docsData]
  let matchingAIDArray = []
  let matchingTypeArray = []
  let combinedMatchesArray = []
  docsDataCopy.map(doc => {
    if (jobsRef.current.value == 'ALL') {

    } else if (doc[2] == jobsRef.current.value) {
      // console.log('job match!')
      matchingAIDArray.push(doc)
    }
    if (billSelectRef.current.value == '') {

    } else if (doc[0] == billSelectRef.current.value) {
      // console.log('type match!')
      matchingTypeArray.push(doc)
    }
    
  })
  if (billSelectRef.current.value != '' && jobsRef.current.value != 'ALL') {
        matchingTypeArray.map((typeMatch) => {
          if (jobsRef.current.value == typeMatch[2]) {
            combinedMatchesArray.push(typeMatch)
          }
        })
        console.log('combined', combinedMatchesArray)
        setDocsDataBucket(combinedMatchesArray)
  } else if (billSelectRef.current.value != '' && jobsRef.current.value == 'ALL') {
    setDocsDataBucket(matchingTypeArray)
  } else if (billSelectRef.current.value == '' && jobsRef.current.value != 'ALL') {
    setDocsDataBucket(matchingAIDArray)
  }
 }

 //resetFunc simply resets the filter. It sets docsDataBucket back to the entire list of documents (aka docsData prop)
 const resetFunc = () => {
  setDocsDataBucket(docsData)
 }
//
//RETURNS
//

//I won't go over the return too much, but do note how docsDataBucket is the state being mapped over.
//This component re-renders whenever the filters are updated or reset.

if (docsDataBucket != []) {
  return(
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <div className='docsBox-header'>
        <div className='card-info-column filter'>
          <div className='medium-text'>AID</div>
          <AIDSelect chosenJobRef={jobsRef} jobsData={jobsData} defaultAID={'ALL'} />
        </div>
        <div className='card-info-column filter'>
        <div className='medium-text'>DOC TYPE</div>
        <select className="input-field medium-text" ref={billSelectRef}>
          <option value="">All Docs</option>
          <option value="docBill">Bill</option>
          <option value="docQuote">Quote</option>
          <option value="docOrder">Order</option>
          <option value="workOrder">Work Order</option>
          <option value="docCO">C.O.</option>
          <option value="docPCO">P.C.O.</option>
        </select>
        </div>
        <div className='card-info-column filter' style={{width: '20%'}}>
          <div className='medium-text'>FILTER</div>
          <div className='input-field button' onClick={filterFunc} style={{fontSize: '2em'}}><BiCheck /></div>
        </div>
        <div className='card-info-column filter' style={{width: '20%'}}>
          <div className='medium-text'>RESET</div>
          <div className='input-field button orange' onClick={resetFunc} style={{fontSize: '2em'}}><BiRecycle /></div>
        </div>
      </div>
      <div className='submittals-wrapper'>
        {docsDataBucket.map((docData, index) => <DocsData refreshDocs={refreshDocs} jobsData={jobsData} baseUrl={baseUrl} docData={docData} setOverlay={setOverlay} key={Math.random()} />)}
      </div>
    </div>
  )
} else {
  return(
    <div className='large-text'>NO MATCHES!!</div>
  )
}
}

export default DocsBox