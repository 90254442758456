import logo from './AAlogo-shadow_white.png'
import './styles.css';
import React, {useEffect, useState} from "react";
import TabContact from './TabContent/TabContact'
import TabHome from './TabContent/TabHome'
import TabPortfolio from './TabContent/TabPortfolio';
import TabServices from './TabContent/TabServices';

function SplashApp() {

const [chosenTab, tabUpdater] = useState('TabHome')


const TabPicker = () => {
  switch (chosenTab) {
    case 'TabHome':
      return (<TabHome />);
    case 'TabServices':
      return (<TabServices />);
    case 'TabContact':
      return (<TabContact />);
    case 'TabPortfolio':
      return (<TabPortfolio />);
    // case 'TabLogin':
    //   return (<TabLogin />);
  }
}

const tabNameUpdater = (e) => {
  tabUpdater(e.target.id)

}







  return (
    <div className="App">
      <header className="App-header">
        <div onClick={tabNameUpdater} id='TabHome' className='nav-button'>
          <img className='logo-img' src={logo}></img>
        </div>
        <div onClick={tabNameUpdater} id='TabServices' className='nav-button'>
          <h2>SERVICES</h2>
        </div>
        <div onClick={tabNameUpdater} id='TabPortfolio' className='nav-button'>
          <h2>PORTFOLIO</h2>
        </div>
        <div onClick={tabNameUpdater} id='TabContact' className='nav-button'>
          <h2>CONTACT</h2>
        </div>
      </header>
      
      <div>
        <div className='white-backdrop'></div>
        <main className='mainContent'>
          <TabPicker />
        </main>
      </div>
    </div>
  );
}

export default SplashApp;
