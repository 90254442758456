import { useEffect, useState } from 'react';
import './App.css';
import OrderComponent from './OrderComponent'
import SubmittalCard from './SubmittalCard';

function SubmittalApp({jobsData,data}) {

  const [chosenSubmittal, setChosenSubmittal] = useState(null)
  const [loadingBool, setLoadingBool] = useState(false)

  useEffect(() => {
    if (chosenSubmittal) {console.log("Chosen Submittal : ", chosenSubmittal)}
  },[chosenSubmittal])

  return (
    <div className="App">
        {(!chosenSubmittal && data) && 
          (
            data.map(val => {
              return(
                <SubmittalCard key={Math.random()} setChosenSubmittal={setChosenSubmittal} jobsData={jobsData} submittalInfo={val} />
              )
            })
          )
        }
        {chosenSubmittal && 
          (
            <OrderComponent chosenURL={chosenSubmittal[1]} />
          )
        }
    </div>
  );
}

export default SubmittalApp;
