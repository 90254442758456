import {useState, useRef, useEffect} from 'react'

const HardwareTinyBlockComponent = ({itemData}) => {


    const [hardwareData, setHardwareData] = useState()

    useEffect(() => {
        try {
            setHardwareData(itemData)
        } catch(err) {
            console.error(err)
        }
        
    }, [])


    if (hardwareData) {
        return (
        
            <div className="hardware-block-wrapper tiny">
                <div className="img-frame hardware">
                <img className='item-img hardware tiny' src={hardwareData[7]}></img>
                </div>
                <div className="hardware-info-wrapper">
                    <div className="info-cell hardware tiny">•MNFR : {hardwareData[1]}</div>
                    <div className="info-cell hardware tiny">•{hardwareData[2]}</div>
                    <div className="info-cell hardware tiny">•MODEL# : {hardwareData[3]} </div>
                    <div className="info-cell hardware tiny">•FINISH : {hardwareData[4]} </div>
                    <div className="info-cell hardware tiny">•QTY : {hardwareData[5]} </div>
                </div>
            </div>
        )        
    } else {
        return (<div>Please wait..</div>)
    }

    
}

export default HardwareTinyBlockComponent