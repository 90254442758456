const InvoicingCompany = ({vendorData, defaultVal, chosenCompanyRef}) => {

  return(
    <select ref={chosenCompanyRef} defaultValue={defaultVal} className="input-field" name="companyField">
      <option value=''>-</option>
      {
        vendorData && vendorData.map((vendor) => {
          return(
            <option value={vendor[0]}>{vendor[1]}</option>
          )
        })
      }

      {/* <option value=''>-</option>
      <option value='acme'>Acme & Dorf</option>
      <option value='lowes'>Lowes</option>
      <option value='homeDepot'>Home Depot</option>
      <option value='amazon'>Amazon</option>
      <option value='navis'>Navis Services</option>
      <option value='trim-work-carpentry'>Trimwork Carpentry</option>
      <option value='max-construction'>Max Construction</option>
      <option value='everest-kitchens'>Everest Kitchens</option>
      <option value='food'>Food</option>
      <option value='gas'>Gasoline</option>
      <option value='expense'>Expense</option> */}
    </select>
  )
}

export default InvoicingCompany