import { useState, useEffect, useRef } from "react";

const Settings = ({appRef,baseUrl,setOverlay}) => {
//
//HOOKS
//

//palette reference holds three different rgb values.
//these three values are later assigned to different CSS variables.
const palette = useRef({
  a: 'rgb(122, 178, 25) ',
  b: 'rgb(210, 177, 10) ',
  c: 'rgb(250, 250, 250)'
})
//
//EFFECTS
//

//
//FUNCS
//

//Firstly we clear the localStorage so we have a fresh start.
//Then we switch over the value, which are just string names for the different themes.
//Then we assign the palette.current reference the three different color codes.
//Lastly we use the three different colors to change the CSS variables, and set the three color codes to localStorage under the name 'palette'
const changePagePalette = (val) => {
localStorage.clear()
  switch(val) {
    case 'money': {
      palette.current = {
        a: 'rgb(122, 178, 25)',
        b: 'rgb(210, 177, 10)',
        c: 'rgb(250, 250, 250)'
      }
    }
    break;
    case 'beach':{
      palette.current = {
        a: '#775ffb',
        b: '#4bfd83',
        c: 'rgb(250, 250, 250)'
      }
    }
    break;
    case 'roserade':{
      palette.current = {
        a: '#7A3E65',
        b: '#F6E1C3',
        c: 'rgb(250, 250, 250)'
      }
    }
    break;
    case 'sage':{
      palette.current = {
        a: '#78866b',
        b: '#CCD5AE',
        c: 'rgb(230, 230, 230)'
      }
    }
    break;
    case 'moonlight': {
      palette.current = {
        a: '#4D455D',
        b: '#7DB9B6',
        c: 'rgb(230, 230, 230)'
      }
    }
    break;
    case 'sleek': {
      palette.current = {
        a: '#2C3333',
        b: '#0E8388',
        c: 'rgb(230, 230, 230)'
      }
    }
    break;
    
  }
  appRef.current.style.setProperty('--greenColor',palette.current.a)
  appRef.current.style.setProperty('--orangeColor',palette.current.b)
  appRef.current.style.setProperty('--goldenRod',palette.current.b)
  appRef.current.style.setProperty('--whiteColor', palette.current.c)
  localStorage.setItem('palette', JSON.stringify(palette.current))
}


//money  rgb(122, 178, 25)  rgb(210, 177, 10)
//beach #fdbb2d #22c1c3
//moonlight #090979 #77006e
//sleek #2C3333 #0E8388
//sage #BBD6B8 #DBE4C6

//
//RETURNS
//

//Must I really explain?
return(<div className="page-stage small">
  Choose A Palette:
  <div className="">
    <div onClick={() => changePagePalette('money')} style={{backgroundImage: 'linear-gradient(90deg,rgb(122, 178, 25) 25%, rgb(210, 177, 10))'}} className="color-selection-wrapper">Money</div>
    <div onClick={() => changePagePalette('beach')} style={{backgroundImage: 'linear-gradient(90deg, #775ffb,#4bfd83 )'}} className="color-selection-wrapper">Beach</div>
    <div onClick={() => changePagePalette('roserade')} style={{backgroundImage: 'linear-gradient(90deg, #7A3E65,#F6E1C3 )'}} className="color-selection-wrapper">Roserade</div>
    <div onClick={() => changePagePalette('moonlight')} style={{backgroundImage: 'linear-gradient(90deg, #4D455D,#7DB9B6 )', color: 'white'}} className="color-selection-wrapper">Moonlight</div>
    <div onClick={() => changePagePalette('sleek')} style={{backgroundImage: 'linear-gradient(90deg,#2C3333 25%,#0E8388 )', color: 'white'}} className="color-selection-wrapper">Sleek</div>
    <div onClick={() => changePagePalette('sage')} style={{backgroundImage: 'linear-gradient(90deg,#78866b 25%,#CCD5AE)', color: 'white'}} className="color-selection-wrapper">Sage</div>

  </div>
  </div>)
}
export default Settings