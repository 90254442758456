import {useRef} from 'react'
const DocFooter = ({footerFormRef, submitForm}) => {

//
//HOOKS
//

//
//EFFECTS
//

//
//FUNCS
//

//
//RETURNS
//

//This incredibly simple return returns the signature select element and the submit button div, formatted inside a set of columns.

  const SignatureSelect = () => {
    return(
    <select name="signatureField" className="input-field">
      <option value='Mike Belfonti'>Mike Belfonti</option>
      <option value='Allison Belfonti'>Allison Belfonti</option>
      <option value='Sebastian Belfonti'>Sebastian Belfonti</option>
    </select>
    )
  }
  
  const SubmitButton = () => {
    return(
    <div name="buttonField" className="input-field button" onClick={submitForm}>
      <div className="">SUBMIT</div>
    </div>
    )
  }

  return(
    <form ref={footerFormRef} className="field-row footer">
      <div className="field-column">
      <label className="medium-text" htmlFor='signatureField'>Signature:</label>
        <SignatureSelect />
      </div>
      <div className="field-column">
      <label className="medium-text" htmlFor='buttonField'>&nbsp;</label>
        <SubmitButton />
      </div>
    </form>
  )
}

export default DocFooter