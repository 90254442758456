import { useState, useEffect, useRef } from "react";
import DocHeader from "./DocHeader";
import DocFooter from './DocFooter';
import {BiDollarCircle} from 'react-icons/bi'
import {Footer,BorderStyle, AlignmentType, TextRun, Packer, Table, TableRow, TableCell, Paragraph, ImageRun, Document, VerticalAlign, HeightRule, FooterWrapper} from "docx"
import { saveAs } from "file-saver";
import { Buffer } from "buffer";

//NOTE: This functional component will be the only of the 5 (very) similar (BillDoc.js, ChangeOrderDoc.js, OrderDoc.js, PChangeOrderDoc.js, QuoteDoc.js)
//that will host comments. I will go into great detail about how this code works, but only in this file. 

const InvoiceDoc = ({docGenPost,jobsData,baseUrl,setOverlay,AMPInvoiceNum,updateAMPTag}) => {
//
//HOOKS
//


//headerFormRef references the header form element that is pulled from DocHeader.js.
const headerFormRef = useRef()

//bodyFormRef references the body form element that is contained in the return section below.
const bodyFormRef = useRef()

//footerFormRef references the footer form element that is pulled from DocFooter.js
const footerFormRef = useRef()

const inputsRef = useRef([])
//
//EFFECTSf
//

//
//FUNCS
//
const handleKeyPress = (e, index) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    if (index + 1 < inputsRef.current.length) {
      inputsRef.current[index + 1].focus();
    }
  }
};

//submitForm obviously.. submits the form, but I'll elaborate up here before the function.
//I believe this function totals close to 1,000 lines, so all comments will remain above, in an attempt not to lose comments in the mess that is this function.
//First: we run two if conditions to check & see if any of the input fields are empty or default, if they are empty strings, the user is alerted to fill in all fields.
//Second: we birth a new FormData object, and append all of the relevant key value pairs to the form.
//Third: we fetch the AMP logo. That fetched image is converted into a buffer, after applying the arrayBuffer() method to the image.
  //This is done to emulate fs.readFileSync, which DOCX (the package that returns a word doc) wants to receive.
//Fourth: on line 1071 a new Document object is established, and it pools together all of the individual doc objects I create, which I'll go over on the next few lines.
//Fifth: we build all of the different document components, which I'll list below.
  //logoTopper is a Paragraph object that contains the ImageRun (the logo), and goes at the top of the page.
  //headerTable contains header data like date, docType, and doc number. Do note the date is manipulated from its traditional YYYY-MM-DD to MM-DD-YYYY
  //up next is addressTable, which hosts the customer/vendor/contact information.
  //after that we have the "spacer table" which is where the user can add any details they'd like to about the particular document.
  //infoTable is the "header" of the table provided in each document. It contains relevant information like the signature, AID, and even a "terms and conditions" section.
  //the largest one is emptyTable, which is what provides the 8 empty rows for the user to fill in as they please. No information is needed for the empty table, it truly does come empty.
  //totalTable is the spaces for sales tax and total tax. Nothing fancy.
  //Lastly we get footerMessage!
//Sixth: we bundle the document into a format that the DOCX package requires, then it is automatically downloaded to the user's computer.
//Seventh: the parcelFormData is sent to the server. The fetch does have error handling.
//That's it! Simple when you look at it this way, huh?

const submitForm = async(e) => {
e.preventDefault()
if (headerFormRef.current[1].value == '' || headerFormRef.current[2].value == '' || headerFormRef.current[3].value == '') {return window.alert('PLEASE FILL IN ALL FIELDS')}
if (bodyFormRef.current[0].value == '' || bodyFormRef.current[1].value == '' || bodyFormRef.current[2].value == '' || bodyFormRef.current[3].value == '' || bodyFormRef.current[4].value == '' || bodyFormRef.current[5].value == '' || bodyFormRef.current[6].value == '') {return window.alert('PLEASE FILL IN ALL FIELDS')}
// console.log(headerFormRef.current[0].value)
// console.log(headerFormRef.current[1].value)
// console.log(headerFormRef.current[2].value)
// console.log(headerFormRef.current[3].value)
// console.log(bodyFormRef.current[0].value)
// console.log(bodyFormRef.current[1].value)
// console.log(bodyFormRef.current[2].value)
// console.log(bodyFormRef.current[3].value)
// console.log(bodyFormRef.current[4].value)
// console.log(bodyFormRef.current[5].value)
// console.log(bodyFormRef.current[6].value)
// console.log(footerFormRef.current[0].value)
let parcelFormData = new FormData()
parcelFormData.append('docuType', 'docBill')
parcelFormData.append('DocID', 'AMP-' + AMPInvoiceNum)
parcelFormData.append('AID', headerFormRef.current[0].value)
parcelFormData.append('date', headerFormRef.current[1].value)
parcelFormData.append('total', headerFormRef.current[2].value)
parcelFormData.append('type', headerFormRef.current[3].value)
parcelFormData.append('billName', bodyFormRef.current[0].value)
parcelFormData.append('billAddress', bodyFormRef.current[1].value)
parcelFormData.append('billCityState', bodyFormRef.current[2].value)
parcelFormData.append('shipName', bodyFormRef.current[3].value)
parcelFormData.append('shipAddress', bodyFormRef.current[4].value)
parcelFormData.append('shipCityState', bodyFormRef.current[5].value)
parcelFormData.append('description', bodyFormRef.current[6].value)
parcelFormData.append('signature', footerFormRef.current[0].value)
//
//HERE IS WHERE I SEND parcelFormData to backend.



//docSave/bill

for (const value of parcelFormData.values()) {
  console.log(value)
}

//
//DOCX
//
let imgurLogo = await fetch ('https://i.imgur.com/tSIfb53.png')
let imgurBuffer = Buffer.from(await imgurLogo.arrayBuffer())

const logoTopper = new Paragraph({children: [
  new ImageRun({
    data: imgurBuffer,
    transformation: {
      width: 204,
      height: 184,
    }
  })
],alignment: AlignmentType.CENTER })

const dateData = new Date();
let dateFormatted = dateData.getMonth() + 1 + '/' + dateData.getDate() + '/' + dateData.getFullYear();

const headerTable = new Table({
  rows: [
    new TableRow({children:[
      new TableCell({
        borders: {
          top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        },children:[
          new Paragraph({children:[
            new TextRun({
              text: 'PO #:  ',
              font: "Arial",
              size: 22,
            }),
            new TextRun({
              text: 'AMP-' + AMPInvoiceNum,
              bold: true,
              font: "Arial",
              size: 24,

            })
          ],
          alignment: AlignmentType.CENTER,
          }),
      ],verticalAlign: VerticalAlign.CENTER,
    }),
    new TableCell({
      borders: {
        top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      },children:[
        new Paragraph({children:[
          new TextRun({
            text: 'INVOICE',
            bold: true,
            font: "Arial",
            size: 24,

          })
        ],
        alignment: AlignmentType.CENTER,
        }),
    ],verticalAlign: VerticalAlign.CENTER,
  }),
      new TableCell({borders: {
        top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        right: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      },children:[
          new Paragraph({children:[
            new TextRun({
              text: 'Date:  ',
              font: "Arial",
              size: 22,

            }),
            new TextRun({
              text: dateFormatted,
              bold: true,
              font: "Arial",
              size: 24,

            })
          ],
          alignment: AlignmentType.CENTER,
          })
      ],
      verticalAlign: VerticalAlign.CENTER,
    })
    ]})
  ],
  width: 0, // AUTO
  columnWidths: [3000, 3100, 3000], // total page width is 9638 DXA for A4 portrait

// indent: {
//     size: 600,
//     type: WidthType.DXA,
// }
})
const addressTable = new Table({rows:[
  new TableRow({children:[
  new TableCell({borders: {top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'}},children:[
      new Paragraph({children:[
        new TextRun({
          text: 'Bill To: ',
          bold: true,
          font: "Arial",
          size: 20,
        }),
      ],
      alignment: AlignmentType.LEFT,
      }),
      new Paragraph({children:[
        new TextRun({
          text: bodyFormRef.current[0].value,
          font: "Times New Roman",
          size: 20,
        }),
      ],
      indent: {left: 300},
      alignment: AlignmentType.LEFT,
      }),
      new Paragraph({children:[
        new TextRun({
          text: bodyFormRef.current[1].value,
          font: "Times New Roman",
          size: 20,
        }),
      ],
      indent: {left: 300},
      alignment: AlignmentType.LEFT,
      }),
      new Paragraph({children:[
        new TextRun({
          text: bodyFormRef.current[2].value,
          font: "Times New Roman",
          size: 20,
        }),
      ],
      indent: {left: 300},
      alignment: AlignmentType.LEFT,
      }),
    ],
    verticalAlign: VerticalAlign.CENTER,
  }),
  new TableCell({borders: {top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'}},children:[
    new Paragraph({children:[
      new TextRun({
        text: 'Ship To: ',
        bold: true,
        font: "Arial",
        size: 20,
      }),
    ],
    alignment: AlignmentType.LEFT,
    }),
    new Paragraph({children:[
      new TextRun({
        text: bodyFormRef.current[3].value,
        font: "Times New Roman",
        size: 20,
      }),
    ],
    indent: {left: 300},
    alignment: AlignmentType.LEFT,
    }),
    new Paragraph({children:[
      new TextRun({
        text: bodyFormRef.current[4].value,
        font: "Times New Roman",
        size: 20,
      }),
    ],
    indent: {left: 300},
    alignment: AlignmentType.LEFT,
    }),
    new Paragraph({children:[
      new TextRun({
        text: bodyFormRef.current[5].value,
        font: "Times New Roman",
        size: 20,
      }),
    ],
    indent: {left: 300},
    alignment: AlignmentType.LEFT,
    }),
  ],
  verticalAlign: VerticalAlign.CENTER,
}),
], height: {value: 1000, rule: HeightRule.EXACT} })
], width: 0, columnWidths: [4550, 4550]

})

const smallSpacer = new Paragraph({children:[
  new TextRun({
    text: '',
    size: 20,
    color: 'FFFFFF',
  })
]
})

const spacerTable = new Table({rows:[
  new TableRow({children:[
    new TableCell({borders: {
      top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      right: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
    },children:[
      new Paragraph({children: [
        new TextRun({
          text: 'Add any details here..'
        })
      ]})
    ]})
  ], height: {value: 1500, rule: HeightRule.EXACT}})
], width:0, columnWidths:[9100,0] })

const infoTable = new Table({rows: [
  new TableRow({children:[
    new TableCell({children:[
      new Paragraph({children:[
        new TextRun({
          text: 'CREATED BY'
        })
      ],alignment: AlignmentType.CENTER}),
      new Paragraph({children:[
        new TextRun({
          text: '',
          size: 8,
          color: 'FFFFFF',
        })
      ]
      }),
      new Paragraph({children:[
        new TextRun({
          text: footerFormRef.current[0].value,
          bold: true,
          size: 24,
        })
      ],alignment: AlignmentType.CENTER})
    ]}),
    new TableCell({children:[
      new Paragraph({children:[
        new TextRun({
          text: 'JOB #'
        })
      ],alignment: AlignmentType.CENTER}),
      new Paragraph({children:[
        new TextRun({
          text: '',
          size: 8,
          color: 'FFFFFF',
        })
      ]
      }),
      new Paragraph({children:[
        new TextRun({
          text: headerFormRef.current[0].value,
          bold: true,
          size: 24,
        })
      ],alignment: AlignmentType.CENTER})
    ]}),
    new TableCell({children:[
      new Paragraph({children:[
        new TextRun({
          text: 'TERMS'
        })
      ],alignment: AlignmentType.CENTER}),
      new Paragraph({children:[
        new TextRun({
          text: '',
          size: 8,
          color: 'FFFFFF',
        })
      ]
      }),
      new Paragraph({children:[
        new TextRun({
          text: "Due in 30 days",
          bold: true,
          size: 24,
        })
      ],alignment: AlignmentType.CENTER})
    ]}),
    
  ], height: {value: 800, rule: HeightRule.EXACT}})
], width: 0, columnWidths: [3100, 3100, 2900]})


const emptyTable = new Table({rows:[
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '#',
        font: 'Arial',
        bold: true,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ],verticalAlign: VerticalAlign.CENTER,}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: 'DESCRIPTION',
        font: 'Arial',
        bold: true,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ],verticalAlign: VerticalAlign.CENTER,}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: 'QTY',
        font: 'Arial',
        bold: true,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ],verticalAlign: VerticalAlign.CENTER,}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: 'UNIT PRICE',
        font: 'Arial',
        bold: true,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ],verticalAlign: VerticalAlign.CENTER,}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: 'SUM PRICE',
        font: 'Arial',
        bold: true,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ],verticalAlign: VerticalAlign.CENTER,}), 
], height: {value: 400, rule: HeightRule.EXACT}}),

new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '1',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '$',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
]}),

]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.CENTER}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
]}),
new TableRow({children:[
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ],alignment: AlignmentType.CENTER})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.LEFT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        font: 'Arial',
        bold: false,
        size: 20,
      })
    ]})
  ],alignment: AlignmentType.RIGHT}), 
]}),
],width: 0, columnWidths: [500, 5000, 700, 1400, 1500]})

const totalClone = +headerFormRef.current[2].value


const totalTable = new Table({rows: [
  new TableRow({children:[
    new TableCell({borders: {
      top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
    },children:[
      new Paragraph({children:[
        new TextRun({
          text: 'SALES TAX: ',
          bold: true,
          size: 24,
        })
      ],alignment: AlignmentType.RIGHT})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text: '',
        bold: true,
        size: 24,
        })
      ],alignment: AlignmentType.RIGHT})
    ]})
  ]}),
  new TableRow({children:[
    new TableCell({borders: {
      top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
      left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
    },children:[
      new Paragraph({children:[
        new TextRun({
          text: 'TOTAL DUE: ',
          bold: true,
          size: 24,
        })
      ],alignment: AlignmentType.RIGHT})
  ]}),
  new TableCell({children:[
    new Paragraph({children:[
      new TextRun({
        text:totalClone.toLocaleString("en-US", {style:"currency", currency:"USD"}),
        bold: true,
        size: 24,
        })
      ],alignment: AlignmentType.RIGHT})
    ]})
  ]})
],width: 0, columnWidths: [7600, 1500]})

const footerMessage = new Footer({children:[
  new Paragraph({children:[
    new TextRun({
      text: 'MAKE ALL CHECKS TO: AMP CONCEPTS LLC, 7 ERICA LANE, GREENWOOD LAKE NY 10925',
      italics: false,
      font: 'EB Garamond',
      size: 20,
    })
  ],alignment: AlignmentType.CENTER}),
  new Paragraph({children:[
    new TextRun({
      text: 'THANK YOU FOR YOUR BUSINESS!',
      italics: true,
      font: 'EB Garamond',
      size: 24,
    })
  ],alignment: AlignmentType.CENTER}),
]})

const billDoc = new Document({
  sections: [
      {
          properties: {
            page: {
              margin: {
                top: 400
              }
            }
          },
          footers:{
            default: footerMessage
          },
          children: [
            logoTopper,
            smallSpacer,
            headerTable,
            smallSpacer,
            addressTable,
            smallSpacer,
            spacerTable,
            smallSpacer,
            infoTable,
            smallSpacer,
            emptyTable,
            smallSpacer,
            totalTable,
            smallSpacer
          ],
      },
  ],
});

Packer.toBlob(billDoc).then((blob) => {
  console.log(blob);
  saveAs(blob, 'AMP-' + AMPInvoiceNum + ' Invoice');
  console.log("Document created successfully");
});
const res = await fetch(baseUrl + 'docSave/bill', {
  method: 'POST',
  body: parcelFormData,
})
.then((res) => {
  if (res.ok) {return res.json()} throw new Error('Bad Request')
})
.then(data => {
  console.log(data)
  setOverlay(data.response)
  updateAMPTag()
})
.catch((error) => [
  setOverlay('failure', error)
])

//END OF SUBMIT FORM
}


//
//RETURNS
//

//The return here is pretty simple, its just a basic form.
//It does take the DocHeader and DocFooter funtional components.

return(
<div>
<div className="large-text orange-icon"><BiDollarCircle /></div>
  <DocHeader headerFormRef={headerFormRef} jobsData={jobsData}/>
  <hr></hr>
  <form ref={bodyFormRef}>
    <div className="field-row">
      <div className="field-column">
        <label htmlFor="billToField" className="medium-text">Bill To:</label>
        <input name="billToField" className="input-field" type="text" 
          onKeyDown={(e) => handleKeyPress(e, 0)} 
          ref={(el) => inputsRef.current[0] = el} 
        />
        <label htmlFor="billAddressField" className="medium-text">Billing Address:</label>
        <input name="billAddressField" className="input-field" type="text"
          onKeyDown={(e) => handleKeyPress(e, 1)} 
          ref={(el) => inputsRef.current[1] = el} 
        />
        <label htmlFor="billCityStateField" className="medium-text">Billing City,State:</label>
        <input name="billCityStateField" className="input-field" type="text"
          onKeyDown={(e) => handleKeyPress(e, 2)} 
          ref={(el) => inputsRef.current[2] = el} 
        />
      </div>
      <div className="field-column">
        <label htmlFor="shipNameField" className="medium-text">Ship To:</label>
        <input name="shipNameField" className="input-field" type="text"
          onKeyDown={(e) => handleKeyPress(e, 3)} 
          ref={(el) => inputsRef.current[3] = el} 
        />
        <label htmlFor="shipAddressField" className="medium-text">Shipping Address:</label>
        <input name="shipAddressField" className="input-field" type="text"
          onKeyDown={(e) => handleKeyPress(e, 4)} 
          ref={(el) => inputsRef.current[4] = el} 
        />
        <label htmlFor="ShipCityStateField" className="medium-text">Shipping City,State:</label>
        <input name="ShipCityStateField" className="input-field" type="text"
          onKeyDown={(e) => handleKeyPress(e, 5)} 
          ref={(el) => inputsRef.current[5] = el} 
        />
      </div>
    </div>
    <hr></hr>
    <label htmlFor="descriptionField" className="medium-text">Brief & Detailed Description:</label>
    <textarea name='descriptionField' className="description-field"  
      onKeyDown={(e) => handleKeyPress(e, 6)} 
      ref={(el) => inputsRef.current[6] = el} 
    />
  </form>
  <DocFooter submitForm={submitForm} footerFormRef={footerFormRef}/>
</div>
  )
}
export default InvoiceDoc