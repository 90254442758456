import { useState, useEffect, useRef } from "react";
import DocHeader from './DocHeader'
import DocFooter from './DocFooter'
import {BiMoney} from 'react-icons/bi'

import {Footer,BorderStyle, AlignmentType, TextRun, Packer, Table, TableRow, TableCell, Paragraph, ImageRun, Document, VerticalAlign, HeightRule, FooterWrapper} from "docx"
import { saveAs } from "file-saver";
import { Buffer } from "buffer";

const QuickBid = ({docGenPost,jobsData,baseUrl,setOverlay,AMPInvoiceNum,updateAMPTag}) => {
  //
  //HOOKS
  //
  const headerFormRef = useRef()
  const bodyFormRef = useRef()
  const footerFormRef = useRef()
  const inputsRef = useRef([])
  //
  //EFFECTS
  //
  
  //
  //FUNCS
  //
  const handleKeyPress = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (index + 1 < inputsRef.current.length) {
        inputsRef.current[index + 1].focus();
      }
    }
  };


  const submitForm = async(e) => {
    e.preventDefault()
    if (headerFormRef.current[1].value == '' || headerFormRef.current[2].value == '' || headerFormRef.current[3].value == '') {return window.alert('PLEASE FILL IN ALL FIELDS')}
    if (bodyFormRef.current[0].value == '' || bodyFormRef.current[1].value == '' || bodyFormRef.current[2].value == '' || bodyFormRef.current[3].value == '' || bodyFormRef.current[4].value == '' || bodyFormRef.current[5].value == '' || bodyFormRef.current[6].value == '') {return window.alert('PLEASE FILL IN ALL FIELDS')}
    let parcelFormData = new FormData()
    parcelFormData.append('docuType', 'quickQuote')
    parcelFormData.append('DocID', 'AMP-' + AMPInvoiceNum)
    parcelFormData.append('AID', headerFormRef.current[0].value)
    parcelFormData.append('date', headerFormRef.current[1].value)
    parcelFormData.append('total', headerFormRef.current[2].value)
    parcelFormData.append('type', headerFormRef.current[3].value)
    parcelFormData.append('contactName', bodyFormRef.current[0].value)
    parcelFormData.append('contactCompany', bodyFormRef.current[1].value)
    parcelFormData.append('architect', bodyFormRef.current[2].value)
    parcelFormData.append('shipNameAddress', bodyFormRef.current[3].value)
    parcelFormData.append('shipCityState', bodyFormRef.current[4].value)
    parcelFormData.append('plansDate', bodyFormRef.current[5].value)
    parcelFormData.append('description', bodyFormRef.current[6].value)
    parcelFormData.append('signature', footerFormRef.current[0].value)
    console.log(parcelFormData)
  
    
  
  
    let imgurLogo = await fetch ('https://i.imgur.com/tSIfb53.png')
    let imgurBuffer = Buffer.from(await imgurLogo.arrayBuffer())
    
    const dateData = new Date();
  let dateFormatted = dateData.getMonth() + 1 + '/' + dateData.getDate() + '/' + dateData.getFullYear();
  
  
    const logoTopper = new Paragraph({children: [
      new ImageRun({
        data: imgurBuffer,
        transformation: {
          width: 204,
          height: 184,
        }
      })
    ],alignment: AlignmentType.CENTER })
    
    const headerTable = new Table({
      rows: [
        new TableRow({children:[
          new TableCell({
            borders: {
              top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
              bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
              left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
            },children:[
              new Paragraph({children:[
                new TextRun({
                  text: 'Doc ID:  ',
                  font: "Arial",
                  size: 22,
    
                }),
                new TextRun({
                  text: 'AMP-' + AMPInvoiceNum,
                  bold: true,
                  font: "Arial",
                  size: 24,
    
                })
              ],
              alignment: AlignmentType.CENTER,
              }),
          ],verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          borders: {
            top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
            bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          },children:[
            new Paragraph({children:[
              new TextRun({
                text: 'ESTIMATE',
                bold: true,
                font: "Arial",
                size: 24,
    
              })
            ],
            alignment: AlignmentType.CENTER,
            }),
        ],verticalAlign: VerticalAlign.CENTER,
      }),
          new TableCell({borders: {
            top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
            bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
            right: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          },children:[
              new Paragraph({children:[
                new TextRun({
                  text: 'Date:  ',
                  font: "Arial",
                  size: 22,
    
                }),
                new TextRun({
                  text: dateFormatted,
                  bold: true,
                  font: "Arial",
                  size: 24,
    
                })
              ],
              alignment: AlignmentType.CENTER,
              })
          ],
          verticalAlign: VerticalAlign.CENTER,
        })
        ]})
      ],
      width: 0, // AUTO
      columnWidths: [3000, 3100, 3000], // total page width is 9638 DXA for A4 portrait
    
    // indent: {
    //     size: 600,
    //     type: WidthType.DXA,
    // }
    })
    const addressTable = new Table({rows:[
      new TableRow({children:[
      new TableCell({borders: {top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'}},children:[
          new Paragraph({children:[
            new TextRun({
              text: 'Contact Information: ',
              bold: true,
              font: "Arial",
              size: 20,
            }),
          ],
          alignment: AlignmentType.LEFT,
          }),
          new Paragraph({children:[
            new TextRun({
              text: bodyFormRef.current[0].value,
              font: "Times New Roman",
              size: 20,
            }),
          ],
          indent: {left: 300},
          alignment: AlignmentType.LEFT,
          }),
          new Paragraph({children:[
            new TextRun({
              text: bodyFormRef.current[1].value,
              font: "Times New Roman",
              size: 20,
            }),
          ],
          indent: {left: 300},
          alignment: AlignmentType.LEFT,
          }),
        ],
        verticalAlign: VerticalAlign.CENTER,
      }),
      new TableCell({borders: {top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'}},children:[
        new Paragraph({children:[
          new TextRun({
            text: 'Ship To: ',
            bold: true,
            font: "Arial",
            size: 20,
          }),
        ],
        alignment: AlignmentType.LEFT,
        }),
        new Paragraph({children:[
          new TextRun({
            text: bodyFormRef.current[3].value,
            font: "Times New Roman",
            size: 20,
          }),
        ],
        indent: {left: 300},
        alignment: AlignmentType.LEFT,
        }),
        new Paragraph({children:[
          new TextRun({
            text: bodyFormRef.current[4].value,
            font: "Times New Roman",
            size: 20,
          }),
        ],
        indent: {left: 300},
        alignment: AlignmentType.LEFT,
        }),
      ],
      verticalAlign: VerticalAlign.CENTER,
    }),
    ], height: {value: 1000, rule: HeightRule.EXACT} })
    ], width: 0, columnWidths: [4550, 4550]
    
    })
    
    const smallSpacer = new Paragraph({children:[
      new TextRun({
        text: '',
        size: 20,
        color: 'FFFFFF',
      })
    ]
    })
    
    const spacerTable = new Table({rows:[
      new TableRow({children:[
        new TableCell({borders: {
          top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          right: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        },children:[
          new Paragraph({children: [
            new TextRun({
              text: 'Supply and Install pricing of --INCLUDE SPECS & DETAILS HERE.--. --DONT FORGET EXLUSION!--'
            })
          ]})
        ]})
      ], height: {value: 1000, rule: HeightRule.EXACT}})
    ], width:0, columnWidths:[9100,0] })
    
    const infoTable = new Table({rows: [
      new TableRow({children:[
        new TableCell({children:[
          new Paragraph({children:[
            new TextRun({
              text: 'CREATED BY'
            })
          ],alignment: AlignmentType.CENTER}),
          new Paragraph({children:[
            new TextRun({
              text: '',
              size: 8,
              color: 'FFFFFF',
            })
          ]
          }),
          new Paragraph({children:[
            new TextRun({
              text: footerFormRef.current[0].value,
              bold: true,
              size: 24,
            })
          ],alignment: AlignmentType.CENTER})
        ]}),
        new TableCell({children:[
          new Paragraph({children:[
            new TextRun({
              text: 'ARCHITECT'
            })
          ],alignment: AlignmentType.CENTER}),
          new Paragraph({children:[
            new TextRun({
              text: '',
              size: 8,
              color: 'FFFFFF',
            })
          ]
          }),
          new Paragraph({children:[
            new TextRun({
              text: bodyFormRef.current[2].value,
              bold: true,
              size: 24,
            })
          ],alignment: AlignmentType.CENTER})
        ]}),
        new TableCell({children:[
          new Paragraph({children:[
            new TextRun({
              text: 'DATE OF PLANS'
            })
          ],alignment: AlignmentType.CENTER}),
          new Paragraph({children:[
            new TextRun({
              text: '',
              size: 8,
              color: 'FFFFFF',
            })
          ]
          }),
          new Paragraph({children:[
            new TextRun({
              text: bodyFormRef.current[5].value,
              bold: true,
              size: 24,
            })
          ],alignment: AlignmentType.CENTER})
        ]}),
        new TableCell({children:[
          new Paragraph({children:[
            new TextRun({
              text: 'BID CODE'
            })
          ],alignment: AlignmentType.CENTER}),
          new Paragraph({children:[
            new TextRun({
              text: '',
              size: 8,
              color: 'FFFFFF',
            })
          ]
          }),
          new Paragraph({children:[
            new TextRun({
              text: "-",
              bold: true,
              size: 24,
            })
          ],alignment: AlignmentType.CENTER})
        ]}),
        
      ], height: {value: 800, rule: HeightRule.EXACT}})
    ], width: 0, columnWidths: [2275, 2275, 2275, 2275]})
    
    const totalClone = +headerFormRef.current[2].value
    const totalTable = new Table({rows: [
  
      new TableRow({children:[
        new TableCell({borders: {
          top: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          bottom: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
          left: {style: BorderStyle.NONE, size: 0, color: 'FFFFFF'},
        },children:[
          new Paragraph({children:[
            new TextRun({
              text: 'TOTAL PROPOSED VALUE: ',
              bold: true,
              size: 28,
            })
          ],alignment: AlignmentType.RIGHT})
      ]}),
      new TableCell({children:[
        new Paragraph({children:[
          new TextRun({
            text:totalClone.toLocaleString("en-US", {style:"currency", currency:"USD"}),
            bold: true,
            size: 28,
            })
          ],alignment: AlignmentType.RIGHT})
        ]})
      ]})
    ],width: 0, columnWidths: [6500, 2600]})
    
    const footerMessage = new Footer({children:[
      new Paragraph({children:[
        new TextRun({
          text: 'THANK YOU FOR YOUR BUSINESS!',
          italics: true,
          font: 'EB Garamond',
          size: 24,
        })
      ],alignment: AlignmentType.CENTER}),
    ]})
    
    const billDoc = new Document({
      sections: [
          {
              properties: {
                page: {
                  margin: {
                    top: 400
                  }
                }
              },
              footers:{
                default: footerMessage
              },
              children: [
                logoTopper,
                smallSpacer,
                headerTable,
                smallSpacer,
                addressTable,
                smallSpacer,
                spacerTable,
                smallSpacer,
                infoTable,
                smallSpacer,
                totalTable,
                smallSpacer
              ],
          },
      ],
    });
    
    Packer.toBlob(billDoc).then((blob) => {
      console.log(blob);
      saveAs(blob, 'AMP-' + AMPInvoiceNum + ' Trim & Door Estimate');
      console.log("Document created successfully");
    });
    
    
    
  
  
    const res = await fetch(baseUrl + 'docSave/quickQuote', {
      method: 'POST',
      body: parcelFormData,
    })
    .then((res) => {
      if (res.ok) {return res.json()} throw new Error('Bad Request')
    })
    .then(data => {
      console.log(data)
      setOverlay(data.response)
      updateAMPTag()
    })
    .catch((error) => [
      setOverlay('failure', error)
    ])
  
  }
//END OF SUBMIT FORM



  return(
    <div>
    <div className="large-text orange-icon"><BiMoney /></div>
      <DocHeader headerFormRef={headerFormRef} jobsData={jobsData}/>
      <hr></hr>
      <form ref={bodyFormRef}>
        <div className="field-row">
          <div className="field-column">
            <label htmlFor="billToField" className="medium-text">Contact Name:</label>
            <input name="billToField" className="input-field" type="text"
                     onKeyDown={(e) => handleKeyPress(e, 0)} 
                     ref={(el) => inputsRef.current[0] = el} 
                   />
            <label htmlFor="billAddressField" className="medium-text">Contact Company:</label>
            <input name="billAddressField" className="input-field" type="text"
                     onKeyDown={(e) => handleKeyPress(e, 1)} 
                     ref={(el) => inputsRef.current[1] = el} 
                   />
            <label htmlFor="billCityStateField" className="medium-text">Architect:</label>
            <input name="billCityStateField" className="input-field" type="text"
                     onKeyDown={(e) => handleKeyPress(e, 2)} 
                     ref={(el) => inputsRef.current[2] = el} 
                   />
          </div>
          <div className="field-column">
            <label htmlFor="shipNameField" className="medium-text">Shipping Name / Address:</label>
            <input name="shipNameField" className="input-field" type="text"
                     onKeyDown={(e) => handleKeyPress(e, 3)} 
                     ref={(el) => inputsRef.current[3] = el} 
                   />
            <label htmlFor="shipAddressField" className="medium-text">Shipping Address:</label>
            <input name="shipAddressField" className="input-field" type="text"
                     onKeyDown={(e) => handleKeyPress(e, 4)} 
                     ref={(el) => inputsRef.current[4] = el} 
                   />
            <label htmlFor="ShipCityStateField" className="medium-text">Date of Plans:</label>
            <input name="ShipCityStateField" className="input-field" type="text"
                     onKeyDown={(e) => handleKeyPress(e, 5)} 
                     ref={(el) => inputsRef.current[5] = el} 
                   />
          </div>
        </div>
        <hr></hr>
        <label htmlFor="descriptionField" className="medium-text">Brief & Detailed Description:</label>
        <textarea name='descriptionField' className="description-field"
                 onKeyDown={(e) => handleKeyPress(e, 6)} 
                 ref={(el) => inputsRef.current[6] = el} 
               />
      </form>
      <DocFooter submitForm={submitForm} footerFormRef={footerFormRef}/>
    </div>
      )

}
export default QuickBid